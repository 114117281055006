import React from "react";

const DocumentUpload = ({ nextStep, prevStep, documents, setDocuments }) => {
  
  const handleFileChange = (index, event) => {
    const newDocuments = [...documents];
    newDocuments[index].file = event.target.files[0];
    setDocuments(newDocuments);
  };

  const handleCategoryChange = (index, event) => {
    const newDocuments = [...documents];
    newDocuments[index].category = event.target.value;
    setDocuments(newDocuments);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, { file: null, category: "" }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    nextStep(); // Move to the next step on submit
  };

  return (
    <form 
      onSubmit={handleSubmit}
      style={{
        maxWidth: "500px",
        margin: "auto",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>Document Upload</h2>
      {documents.map((doc, index) => (
        <div
          key={index}
          className="document-upload"
          style={{
            marginBottom: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#fff",
          }}
        >
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Select Category:
            <select
              value={doc.category}
              onChange={(event) => handleCategoryChange(index, event)}
              style={{
                width: "100%",
                padding: "8px",
                marginTop: "5px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                backgroundColor: "#f7f7f7",
                color: "#333",
              }}
            >
              <option value="">--Select Category--</option>
              <option value="passport">Passport</option>
              <option value="transcript">Transcript</option>
              <option value="certificate">Certificate</option>
              <option value="cv">CV</option>
              <option value="other">Other</option>
            </select>
          </label>

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Upload Document:
            <input
              type="file"
              onChange={(event) => handleFileChange(index, event)}
              style={{
                width: "100%",
                padding: "8px",
                marginTop: "5px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                backgroundColor: "#f7f7f7",
                color: "#333",
              }}
            />
          </label>
        </div>
      ))}

      <button
        type="button"
        onClick={handleAddDocument}
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "20px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#4CAF50",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Add More Documents
      </button>

      <button
        type="submit"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "20px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#008CBA",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Next
      </button>

      <button
        type="button"
        onClick={prevStep}
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#f44336",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Previous
      </button>
    </form>
  );
};

export default DocumentUpload;
