import React, { useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';
import Menus from './Menus';
import ContentsWeb from './ContentsWeb';
import Users from './Users';
import Suggestion from './Suggestion';
import TeamController from './TeamController';
import SectionWebController from './SectionWebController';
import OthersInfoWbController from './OthersInfoWbController';
import StudyServices from './StudyServices';
import Locations from './Locations';
import BrandManagement from './BrandManagement';




const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const RegisterTabs = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="register tabs"
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        variant={isSmallScreen ? 'scrollable' : 'standard'}
        sx={{
          borderRight: isSmallScreen ? 1 : 0,
          borderColor: 'divider',
          width: isSmallScreen ? '100%' : 'auto',
          marginBottom:5
        }}
      >
        <Tab label="Contents" />
        <Tab label="Users" />
        <Tab label="Suggestions" />
        <Tab label="Team" />
        <Tab label="Sections" />
        <Tab label="Company Info" />
        <Tab label="Menu" />
        <Tab label="Services" />
        <Tab label="Brands" />
        <Tab label="Locations" />

      </Tabs>
      <TabPanel value={value} index={0}>
        <ContentsWeb/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Users/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Suggestion/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TeamController />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SectionWebController />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <OthersInfoWbController />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Menus/>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <StudyServices/>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <BrandManagement/>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Locations/>
      </TabPanel>
    </Box>
  );
};

export default RegisterTabs;
