import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, IconButton, Skeleton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import axios from 'axios';

const ContactSection = () => {
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllInfo = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/other-info`);
        setInfos(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllInfo();
  }, []);

  return (
    <Box sx={{ padding: '4rem 2rem', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h3" component="h2" gutterBottom>
        Contact Us
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '2rem' }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Our Office
            </Typography>

            {loading ? (
              <>
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={20} width="80%" />
                <Skeleton variant="text" height={20} width="60%" sx={{ mt: 2 }} />
                <Skeleton variant="text" height={20} width="60%" />
              </>
            ) : (
              infos.map((info, index) => (
                <div key={index}>
                  <Typography variant="body1" component="p">
                    {info.location}
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                    {info.phone}<br />
                    {info.email}
                  </Typography>
                </div>
              ))
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1em' }}>
              <IconButton color="inherit">
                <Facebook color="primary" />
              </IconButton>
              <IconButton color="inherit">
                <Twitter style={{ color: '#1DA1F2' }} />
              </IconButton>
              <IconButton color="inherit">
                <LinkedIn style={{ color: '#0077B5' }} />
              </IconButton>
              <IconButton color="inherit">
                <Instagram style={{ color: '#d62976' }} />
              </IconButton>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0867077304636!2d144.96328!3d-37.813628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5779b971c43c2a7!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1624152117638!5m2!1sen!2sau"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Location"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSection;
