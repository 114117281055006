import React from 'react';
import Header from '../components/header';
import Footer from '../components/Footer';
import BookFlightInfo from './BookFlightInfo';
import FAQSectionFlight from './FAQSectionFlight';
import HeroFlight from './HeroFlight';


export default function FlightPage() {
  return (
    <div>
      <Header />
      <HeroFlight/>
        <BookFlightInfo/>
        <FAQSectionFlight/>
      <Footer />
    </div>
  );
}
