import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import countries from '../json/countriesList.json'

const PersonalInfo = ({ nextStep, handleChange, userData }) => {
  const { fullName, dateOfbirth,martalStatus,gender, passport,expireDate, identification,language } = userData;
  const [valid,setValid] = useState("")
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const continueStep = e => {
    e.preventDefault();

    
  if(fullName && dateOfbirth && gender && language && martalStatus){
    nextStep();
  }
   setValid("fill all informations to continue!!")
  };
  return (
      <Box
      component="form"
      sx={{
        display:'flex',
       flex:1,
       flexDirection:'column',
       gap:3
      }}
      noValidate
      autoComplete="off"
    >
       <h2 style={{marginBottom:20}}>Personal Information</h2>
       <h4 style={{color:'red'}}>{valid}</h4>
      <FormControl fullWidth>
         <TextField id="outlined-basic" label="Enter Full Name" variant="outlined" value={fullName} onChange={handleChange('fullName')} required />
      </FormControl>
      <FormControl fullWidth>
         <TextField defaultValue={Date()}       
         inputProps={{
         max: getCurrentDate(), 
      }} id="outlined-basic" label="Date of Birth" variant="outlined" type='date' InputLabelProps={{ shrink: true }} value={dateOfbirth} onChange={handleChange('dateOfbirth')} required />
      </FormControl>
        <FormControl>
      <TextField
        label="Nationality"
        variant="outlined"
        select
        fullWidth
        value={userData.nationality}
        onChange={handleChange('nationality')}
      >
        {countries.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </TextField>
      </FormControl>
        <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={gender}
        onChange={handleChange('gender')}
      >
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>

    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">   Martal Status</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="Single"
        name="radio-buttons-group"
        value={martalStatus}
        onChange={handleChange('martalStatus')}
      >
        <FormControlLabel value="Single" control={<Radio />} label="Single" />
        <FormControlLabel value="Married" control={<Radio />} label="Married" />
      </RadioGroup>
    </FormControl>
    <FormControl fullWidth>
        <TextField id="outlined-basic" label="First Language" variant="outlined" type="text" value={language} onChange={handleChange('language')} required/>
        </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Do you have Passport?</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={passport}
          label="Do you have Passport?"
          onChange={handleChange('passport')}
        >
          <MenuItem value='Yes'>Yes</MenuItem>
          <MenuItem value='No'>No</MenuItem>
          
        </Select>
      </FormControl>
      {passport==='Yes'?
      <>
      <FormControl fullWidth>
        <TextField id="outlined-basic" label="Enter Passport" variant="outlined" type="text" value={identification} onChange={handleChange('identification')} />
     </FormControl>
     <FormControl fullWidth>
         <TextField defaultValue={Date()} 
               
         inputProps={{
          min: getCurrentDate(),
      }} id="outlined-basic" label="Expired Date" variant="outlined" type='date' InputLabelProps={{ shrink: true }} value={expireDate} onChange={handleChange('expireDate')} required />
      </FormControl>
     </>
     :passport==='No'?
     <FormControl fullWidth>
        <TextField id="outlined-basic" label="Enter your ID" variant="outlined"  type="number" value={identification}
         onChange={handleChange('identification')}       
         inputProps={{
          maxLength: 16, // Set the max length
          inputMode: 'numeric', // Ensures that the input is numeric on mobile keyboards
          pattern: '[0-9]*', // Ensures only numeric input
        }}/>
        </FormControl>:null}
     <FormControl >
      <Button variant="contained" onClick={continueStep}>Next</Button>
    </FormControl>
    </Box>

  );
};

export default PersonalInfo;