import React from 'react';
import './styles/style.css'; // Add a CSS file for styling and animations

const InternationalSection = () => {
  return (
    <div className="camp-container">
      {/* Header Section */}
      <header className="camp-header">
        <h1 className="camp-title">Welcome to Our International Camp</h1>
        <p className="camp-subtitle">Explore, Learn, and Grow with Us!</p>
      </header>

      {/* Key Factors Section */}
      <section className="key-factors">
        <h2 className="section-title">What Makes Our Camp Special?</h2>
        <div className="factors-list">
          {["Welcoming", "Adventurous", "Engaging", "Exciting", "Respectful", "Global"].map((factor, index) => (
            <div key={index} className="factor-item bounce">
              {factor}
            </div>
          ))}
        </div>
      </section>

      {/* WHY, HOW, WHAT Section */}
      <section className="camp-details">
        <div className="camp-card fade-in">
          <h3 className="card-title">WHY</h3>
          <p>New experiences help you grow, offering opportunities to discover more about yourself and the world around you.</p>
        </div>

        <div className="camp-card fade-in delay">
          <h3 className="card-title">HOW</h3>
          <p>Through dynamic lessons and engaging activities, we ensure every camper stays active and enjoys the learning process.</p>
        </div>

        <div className="camp-card fade-in delay-2">
          <h3 className="card-title">WHAT</h3>
          <p>We offer an award-winning Junior camp, staffed with professionals dedicated to safety, learning, and fun for all ages.</p>
        </div>
      </section>
    </div>
  );
};

export default InternationalSection;
