import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const OthersInfoWbController = () => {
  const columns = [
    { field: 'location', headerName: 'Title', width: 230 },
    { field: 'email', headerName: 'Position', width: 230 },
    { field: 'phone', headerName: 'Message', width: 160 },
    { field: 'facebook', headerName: 'Facebook', width: 160 },
    { field: 'instagram', headerName: 'Instagram', width: 160 },
    { field: 'twitter', headerName: 'Twitter', width: 160 },
    { field: 'linkdin', headerName: 'LinkDin', width: 160 },
    { field: 'tiktok', headerName: 'TikTok', width: 160 },
    { field: 'youtube', headerName: 'Youtube', width: 160 },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'timess', headerName: 'Registration Time', width: 230 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)} color='primary'>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color='error'>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState({
    location: '',
    email: '',
    phone: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkdin: '',
    tiktok: '',
    youtube: '',
    section: '',
  });
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);
  const [sections, setSections] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const handleinfo = (e) => {
    const { name, value } = e.target;
    setTeams({
      ...teams,
      [name]: value,
    });
  };

  setTimeout(()=>{
    
  })

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setEditingId(null);
    setTeams({
      location: '',
      email: '',
      phone: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkdin: '',
      tiktok: '',
      youtube: '',
      section: '',
    });
  };

  useEffect(() => {
    const fetchAllMenu = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/other-info`);
        setRows(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMenu();
  }, []);

  useEffect(() => {
    const fetchAllSection = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section`);
        setSections(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllSection();
  }, []);

  const validateForm = () => {
    if (teams.location === '' || teams.email === '' || teams.phone === '' || teams.section === '') {
      setErr(true);
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (editingId) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/other-info/${editingId}`, teams);
        setAlert('Form updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/other-info`, teams);
        setAlert('Form submitted successfully!');
      }
      setErr(false);
      handleEventDialogClose();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/other-info`);
      setRows(res.data);
    } catch (error) {
      setErr(true);
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (row) => {
    setEditingId(row.id);
    setTeams({
      location: row.location,
      email: row.email,
      phone: row.phone,
      facebook: row.facebook,
      instagram: row.instagram,
      twitter: row.twitter,
      linkdin: row.linkdin,
      tiktok: row.tiktok,
      youtube: row.youtube,
      section: row.section,
    });
    handleEventDialogOpen();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/other-info/${id}`);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/other-info`);
      setRows(res.data);
      setAlert('Record deleted successfully!');
      setErr(false);
    } catch (error) {
      setAlert('Error deleting record.');
      setErr(true);
      console.error('Error deleting record:', error);
    }
  };

  return (
    <Box>
      {alert && (
        <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            Other Information Registration Form
          </Typography>
          <FormFieldContainer>
            {alert && (
              <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>
            )}
            <TextField
              margin="normal"
              fullWidth
              id="location"
              label="Location"
              name="location"
              autoComplete="location"
              autoFocus
              onChange={handleinfo}
              value={teams.location}
            />
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleinfo}
              value={teams.email}
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              autoComplete="phone"
              autoFocus
              onChange={handleinfo}
              value={teams.phone}
            />
            <TextField
              margin="normal"
              fullWidth
              id="facebook"
              label="Facebook"
              name="facebook"
              autoComplete="facebook"
              onChange={handleinfo}
              value={teams.facebook}
            />
            <TextField
              margin="normal"
              fullWidth
              id="instagram"
              label="Instagram"
              name="instagram"
              autoComplete="instagram"
              onChange={handleinfo}
              value={teams.instagram}
            />
            <TextField
              margin="normal"
              fullWidth
              id="twitter"
              label="Twitter"
              name="twitter"
              autoComplete="twitter"
              onChange={handleinfo}
              value={teams.twitter}
            />
            <TextField
              margin="normal"
              fullWidth
              id="linkdin"
              label="Linkdin"
              name="linkdin"
              autoComplete="linkdin"
              onChange={handleinfo}
              value={teams.linkdin}
            />
            <TextField
              margin="normal"
              fullWidth
              id="tiktok"
              label="Tiktok"
              name="tiktok"
              autoComplete="tiktok"
              onChange={handleinfo}
              value={teams.tiktok}
            />
            <TextField
              margin="normal"
              fullWidth
              id="youtube"
              label="Youtube"
              name="youtube"
              autoComplete="youtube"
              onChange={handleinfo}
              value={teams.youtube}
            />
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Section</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Selection Section"
              name="section"
              onChange={handleinfo}
              value={teams.section || ''}
            >
              {sections.map((section) => (
                <MenuItem key={section.id} value={section.id}>
                  {section.title}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </FormFieldContainer>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {editingId ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default OthersInfoWbController;
