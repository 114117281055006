// src/ServicesSection.js
import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Box, Grow } from '@mui/material';
import axios from 'axios';
import './styles/services.css'; // Import custom styles

export default function ServicesSection() {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchContents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
      setContents(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", pb: "5%" }}> {/* Changed padding to pb */}
      <Typography variant="h3" gutterBottom sx={{ ml: '5%', mt: 4 }}>
        EarthLink Group's Brands
      </Typography>
      <Stack
        sx={{
          display: 'flex',
          gap: '50px',
          flexDirection: { xs: 'column', md: 'row' },
          ml: '5%',
          mr: '5%',
          mt: '5%',
          justifyContent: 'space-between',
          mb: '5%',
        }}
      >
        {loading ? (
          [1, 2, 3].map((_, index) => (
            <Paper key={index} sx={{ maxWidth: 400, transition: '0.3s' }} elevation={20}>
              <Skeleton variant="rectangular" width={400} height={200} />
              <CardContent>
                <Skeleton variant="text" width="60%" height={30} />
                <Skeleton variant="text" width="80%" height={20} />
              </CardContent>
              <CardActions>
                <Skeleton variant="rectangular" width={100} height={36} />
              </CardActions>
            </Paper>
          ))
        ) : (
          contents.map((content, index) => (
            <Grow key={index} in={true} timeout={index * 300 + 300}>
              <Paper
                className="paper-card"
                sx={{
                  maxWidth: 400,
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                  '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
                elevation={20}
              >
                <CardMedia
                  component="img"
                  alt="EarthLink business"
                  height="200"
                  image={`${process.env.REACT_APP_API_URL}/uploads/${content.image}`}
                  sx={{
                    transition: 'opacity 0.3s ease-in-out',
                    '&:hover': { opacity: 0.8 },
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {content.brand_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {content.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  {content.brand_name === 'EarthLink overseas education' ? (
                    <>
                      <Button size="large" component="a" href="/study-abroad" color="primary">More Information</Button>
                    </>
                  ) : content.brand_name === 'EarthLink travels and tours' ? (
                    <>
                      <Button size="large" component="a" href="/air-ticket" color="primary">More Information</Button>
                    </>
                  ) : (
                    <>
                      <Button size="large" color="primary" component="a" href="/business-abroad">More Information</Button>
                    </>
                  )}
                </CardActions>
              </Paper>
            </Grow>
          ))
        )}
      </Stack>
    </Box>
  );
}
