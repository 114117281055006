
import React, { useState } from 'react';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';


const Confirmation = ({ prevStep, userData }) => {
  const { fullName, dateOfbirth, martalStatus, passport, identification,gender,expireDate,language,nationality, educationCountry,highestlevel,grade,graduatedSchool,graduationDate,studyfield,address,email,phone,documents } = userData;
  const [result,setResult] = useState("")
  const previousStep = e => {
    e.preventDefault();
    prevStep();
  };

  const close =()=>{

  }

  const submitForm = async e => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8080/api/userdata', userData);
      console.log('Data submitted successfully:', response.data);
      setResult("success")
    } catch (err) {
      console.error('Error submitting data:', err);
      setResult("fail")
    }
  };

  return (
<>

  {result==="success"?    
  <Box
    component="form"
    sx={{
      display:'flex',
     flex:1,
     flexDirection:'column',
     gap:3
    }}
    noValidate
    autoComplete="off"
  >
     <h2 style={{marginBottom:20}}>Message</h2>
    <Alert severity="success" sx={{height:80,justifyContent:'center',alignItems:'center',fontSize:20}}>you have successfully registered as {userData.fullName}, Please check {userData.email} for Confirmation</Alert>
    <Button variant="contained" onClick={close}><a href='http://localhost:3000/' style={{textDecoration:'none',color:'white',fontSize:20}}>Close</a></Button>
  </Box> :result==="fail"?
  <Box
  component="form"
  sx={{
    display:'flex',
   flex:1,
   flexDirection:'column',
   gap:3
  }}
  noValidate
  autoComplete="off"
>
   <h2 style={{marginBottom:20}}>Message</h2>
   <Alert severity="error" sx={{height:80,justifyContent:'center',alignItems:'center',fontSize:20}}>Registration failed Try Again.</Alert>
   <Button variant="contained" onClick={close}><a href='http://localhost:3000/' style={{textDecoration:'none',color:'white',fontSize:20}}>Close</a></Button>

</Box>:
  <Box
    component="form"
    sx={{
      display:'flex',
     flex:1,
     flexDirection:'column',
    }}
    noValidate
    autoComplete="off"
  >
     <h2 style={{marginBottom:5}}>Confirmation</h2>


     
     <List sx={{display:'flex',gap:-25,flexDirection:'column'}}>
      <h3 style={{marginBottom:0}}>Personal Information:</h3>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`Full Names :`} />
        <ListItemText primary={`${fullName}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`dateOfbirth :`} />
        <ListItemText primary={`${dateOfbirth}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`Gender :`} />
        <ListItemText primary={`${gender}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`martalStatus :`} />
        <ListItemText primary={`${martalStatus}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`Identification :`} />
        <ListItemText primary={`${identification}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`passport :`} />
        <ListItemText primary={`${passport}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`expireDate :`} />
        <ListItemText primary={`${expireDate}`} />
      </ListItem>      
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`language :`} />
        <ListItemText primary={`${language}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`nationality :`} />
        <ListItemText primary={`${nationality}`} />
      </ListItem>
      </List>
    <Divider/>
     
     <List sx={{display:'flex',gap:-25,flexDirection:'column'}}>
      <h3 style={{marginBottom:5}}>Education Information:</h3>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`educationCountry :`} />
        <ListItemText primary={`${educationCountry}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`highestlevel :`} />
        <ListItemText primary={`${highestlevel}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`grade :`} />
        <ListItemText primary={`${grade}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`graduatedSchool :`} />
        <ListItemText primary={`${graduatedSchool}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`graduationDate :`} />
        <ListItemText primary={`${graduationDate}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`studyfield :`} />
        <ListItemText primary={`${studyfield}`} />
      </ListItem>
      </List>
    <Divider/>
    
     <List sx={{}}> 
     <h3 style={{marginBottom:0}}>Address Information:</h3>
     <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`Address :`} />
        <ListItemText primary={`${address}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`Email Address :`} />
        <ListItemText primary={`${email}`} />
      </ListItem>
      <ListItem sx={{marginBottom:-2}}>
        <ListItemText primary={`PhoneNumber :`} />
        <ListItemText primary={`${phone}`} />
      </ListItem>
      </List>

      <Divider/>
      <List sx={{}}> 
     <h3 style={{marginBottom:0}}>Documents:</h3>
     <ListItem sx={{marginBottom:-2}}>
      {documents.map(document=>(
        <>
        <ListItemText primary={`Uploaded Documents :`} />
        <ListItemText primary={`${document.category}`} />
        <ListItemText primary={`${document.file[0]}`} />

        </>
      ))}

      </ListItem>
      </List>


      <FormControl style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
      <Button variant="contained" onClick={previousStep}>Back</Button>
      <Button variant="contained" onClick={submitForm}>Submit</Button>
    </FormControl>
  </Box>}</>
  );
};

export default Confirmation;