import React, { useEffect, useState } from 'react';
import { Box, TextField, IconButton, Button, Typography, Dialog, DialogActions, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Locations = () => {
  const columns = [
    { field: 'location', headerName: 'Location', width: 230 },
    { field: 'summary', headerName: 'Summary', width: 230 },
    {
      field: 'flag',
      headerName: 'Flag Picture',
      width: 160,
      renderCell: (params) => (
        <Box component="img" src={`${process.env.REACT_APP_API_URL}/uploads/${params.value}`} alt="Flag" sx={{ width: 50, height: 50,marginLeft:5 }} />
      ),
    },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'timess', headerName: 'Registration Time', width: 230 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [locations, setLocations] = useState({
    id: '',
    location: '',
    summary: '',
    flag: '',
  });
  const [alert, setAlert] = useState(null);

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setLocations({
      ...locations,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setLocations({
      ...locations,
      flag: e.target.files[0],
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setIsEditing(false);
    setLocations({
      id: '',
      location: '',
      summary: '',
      flag: '',
    });
  };

  const fetchAllLocations = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`);
      setRows(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const validateForm = () => {
    if (locations.location === '' || locations.summary === '' || locations.flag === '') {
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    Object.keys(locations).forEach((key) => {
      formData.append(key, locations[key]);
    });

    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/locations/${locations.id}`, formData);
        setAlert('Location updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/locations`, formData);
        setAlert('Location added successfully!');
      }
      fetchAllLocations();
      handleEventDialogClose();
    } catch (error) {
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (row) => {
    setLocations({
      id: row.id,
      location: row.location,
      summary: row.summary,
      flag: row.flag,
    });
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/locations/${id}`);
      setAlert('Location deleted successfully!');
      fetchAllLocations();
    } catch (error) {
      setAlert('Error deleting location.');
      console.error('Error deleting location:', error);
    }
  };

  return (
    <Box>
      {alert && (
        <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {isEditing ? 'Edit Location' : 'Location Registration Form'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="location"
              label="Location"
              name="location"
              autoComplete="location"
              autoFocus
              value={locations.location}
              onChange={handleLocationChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="summary"
              label="Summary"
              name="summary"
              autoComplete="summary"
              value={locations.summary}
              onChange={handleLocationChange}
            />
            <TextField
              margin="normal"
              fullWidth
              type="file"
              id="flag"
              name="flag"
              onChange={handleFileChange}
            />
          </FormFieldContainer>
          {alert && (
            <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
          )}
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {isEditing ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default Locations;
