import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const BrandManagement = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [brandData, setBrandData] = useState({
    brand_name: '',
    description: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [editingId, setEditingId] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBrandData({
      ...brandData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setEditingId(null);
    setBrandData({
      brand_name: '',
      description: '',
    });
    setImageFile(null);
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
        setRows(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBrands();
  }, []);

  const validateForm = () => {
    if (brandData.brand_name === '' || brandData.description === '') {
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append('brand_name', brandData.brand_name);
    formData.append('description', brandData.description);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      if (editingId) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/brands/${editingId}`, formData);
        setAlert('Brand updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/brands`, formData);
        setAlert('Brand added successfully!');
      }
      handleEventDialogClose();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
      setRows(res.data);
    } catch (error) {
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (row) => {
    setEditingId(row.id);
    setBrandData({
      brand_name: row.brand_name,
      description: row.description,
    });
    setImageFile(null);
    handleEventDialogOpen();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/brands/${id}`);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
      setRows(res.data);
      setAlert('Brand deleted successfully!');
    } catch (error) {
      setAlert('Error deleting brand.');
      console.error('Error deleting brand:', error);
    }
  };

  const columns = [
    { field: 'brand_name', headerName: 'Brand Name', width: 300 },
    { field: 'description', headerName: 'Description', width: 400 },
    { 
      field: 'image', 
      headerName: 'Image', 
      width: 300,
      renderCell: (params) => (
          params.row.image ? <img src={`${process.env.REACT_APP_API_URL}/uploads/${params.row.image}`} alt="Brand" style={{ width: '30%', height: 'auto',marginLeft:50 }} /> : 'No Image'
      )
  },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
console.log(brandData)
  return (
    <Box>
      {alert && (
        <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New Brand
      </Button>
      <Box sx={{ width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 15, 20]}
          sx={{ height: 400 }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {editingId ? 'Edit Current Brand' : 'Add New Brand'}
          </Typography>
          <FormFieldContainer>
            <TextField
              label="Brand Name"
              name="brand_name"
              onChange={handleInputChange}
              value={brandData.brand_name}
              fullWidth
            />
            <TextField
              label="Description"
              name="description"
              onChange={handleInputChange}
              value={brandData.description}
              fullWidth
            />
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleFileChange}
            />
          </FormFieldContainer>
          {alert && (
            <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
          )}
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {editingId ? 'Update' : 'Submit'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default BrandManagement;
