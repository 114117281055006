import React from 'react';
import './styles/style.css'; // Custom styling for animations

const VisaServices = () => {
  const services = [
    {
      title: 'Visa Consultation',
      description: 'Expert advice on visa requirements, documentation, and processes for different countries.'
    },
    {
      title: 'Visa Application Assistance',
      description: 'Step-by-step guidance in completing visa application forms and submitting supporting documents.'
    },
    {
      title: 'Document Verification',
      description: 'Verification of all documents to ensure they meet the requirements for visa approval.'
    },
    {
      title: 'Visa Fee Processing',
      description: 'Help with payment of visa fees, ensuring they are correctly processed and submitted.'
    },
    {
      title: 'Appointment Scheduling',
      description: 'Assistance with booking embassy appointments, interviews, or biometric scans.'
    },
    {
      title: 'Visa Tracking',
      description: 'Ongoing updates on the status of visa applications, keeping you informed every step of the way.'
    },
    {
      title: 'Visa Appeal Assistance',
      description: 'Help with appeal processes in case of a visa denial, including legal recommendations.'
    },
    {
      title: 'Travel Insurance Guidance',
      description: 'Advice on acquiring necessary travel insurance for visa application and travel.'
    },
    {
      title: 'Legal and Immigration Support',
      description: 'Support for complex legal immigration cases or issues that require further attention.'
    },
    {
      title: 'Expedited Visa Processing',
      description: 'Fast-track services for urgent visa needs, ensuring quicker processing and approval.'
    }
  ];

  return (
    <div className="visa-services-container">
      <div className="title-container">
        <h1 className="title">EarthLink Visa Services</h1>
        <p className="summary">
          EarthLink Visa provides specialized services to help individuals and businesses navigate the often complex
          and confusing process of obtaining visas. We offer consultations, assist with paperwork, verify documents,
          and expedite the visa approval process, ensuring everything runs smoothly.
        </p>
      </div>

      <div className="services-list">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisaServices;
