import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, Typography, Button, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion'; // Import Framer Motion for animation

const ImageContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '12px', // Slightly more rounded corners for a modern look
  cursor: 'pointer',
  '&:hover .overlay': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '&:hover .location-name': {
    opacity: 0,
  },
  '&:hover img': {
    opacity: 0.3,
  },
});

const Image = styled('img')({
  width: '100%',
  height: '300px',
  objectFit: 'cover',
  transition: 'opacity 0.5s ease', // Smoother opacity transition
  borderRadius: '12px', // Match the border radius of the container
});

const Overlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  padding: '20px',
  textAlign: 'center',
  opacity: 0,
  transform: 'translateY(100%)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  borderRadius: '12px', // Ensure the overlay follows the rounded corners
});

const LocationName = styled(Typography)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: '#fff',
  padding: '12px',
  borderRadius: '8px',
  zIndex: 1,
  transition: 'opacity 0.5s ease',
});

export default function Destinations() {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});
  const [showAll, setShowAll] = useState(false); // Track if "See More" was clicked

  const fetchAllLocations = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`);
      setDestinations(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const handleReadMoreToggle = useCallback((id) => {
    setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);

  const maxSummaryLength = 100;

  const displayedDestinations = showAll ? destinations : destinations.slice(0, 8);

  return (
    <Box sx={{ padding: '4rem 2rem', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '3rem' }}>
        Destinations
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {loading ? (
          [1, 2, 3, 4].map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Skeleton variant="rectangular" width="100%" height={300} />
            </Grid>
          ))
        ) : (
          displayedDestinations.map((destination) => (
            <Grid item xs={12} sm={6} md={3} key={destination.id}>
              {/* Wrapping the entire card in a motion.div for animation */}
              <motion.div
                whileHover={{ scale: 1.05 }} // Slight scale-up on hover for the entire card
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * destination.id }} // Delay for staggered effect
              >
                <ImageContainer>
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/uploads/${destination.flag}`}
                    alt={destination.location}
                    loading="lazy"
                  />
                  <LocationName className="location-name" variant="h5">
                    {destination.location}
                  </LocationName>
                  <Overlay className="overlay">
                    <Typography variant="h5" gutterBottom>
                      {destination.location}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {expanded[destination.id] || destination.summary.length <= maxSummaryLength
                        ? destination.summary
                        : `${destination.summary.substring(0, maxSummaryLength)}...`}
                    </Typography>
                    {destination.summary.length > maxSummaryLength && (
                      <Button
                        size="small"
                        onClick={() => handleReadMoreToggle(destination.id)}
                        sx={{ color: '#fff', textTransform: 'none', mt: 1 }}
                      >
                        {expanded[destination.id] ? 'Read Less' : 'Read More'}
                      </Button>
                    )}
                    <Button
                      size="large"
                      component="a"
                      href="/schools"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2, backgroundColor: '#007bff', textTransform: 'none' }}
                    >
                      More Information
                    </Button>
                  </Overlay>
                </ImageContainer>
              </motion.div>
            </Grid>
          ))
        )}
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowAll(!showAll)}
        sx={{ mt: 4 }}
      >
        {showAll ? 'Show Less' : 'Show More'}
      </Button>
    </Box>
  );
}
