import React from 'react'
import HeroVisa from './HeroVisa'
import Header from '../components/header'
import Footer from '../components/Footer'
import GoodToknow from './GoodToknow'
import VisaServices from './VisaServices'

export default function LandingVisa() {
  return (
    <div>
      <Header/>
        <HeroVisa/>
        <GoodToknow/>
        <VisaServices/>
        <Footer/>
    </div>
  )
}
