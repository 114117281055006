import React, { useEffect, useState } from 'react';
import { Box, TextField, IconButton, Button, Typography, Dialog, DialogActions, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const usertypes = ['Admin', 'normal users'];

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Users = () => {
  const columns = [
    { field: 'names', headerName: 'Names', width: 230 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'profile', headerName: 'Profile Picture', width: 160 },
    { field: 'userType', headerName: 'Type of User', width: 160 },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'timess', headerName: 'Registration Time', width: 230 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState({
    id: '',
    names: '',
    email: '',
    password: '',
    usertype: '',
    profile: '',
  });
  const [alert, setAlert] = useState(null);

  const handleUsersChange = (e) => {
    const { name, value } = e.target;
    setUsers({
      ...users,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setUsers({
      ...users,
      profile: e.target.files[0],
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setIsEditing(false);
    setUsers({
      id: '',
      names: '',
      email: '',
      password: '',
      usertype: '',
      profile: '',
    });
  };

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
      setRows(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const validateForm = () => {
    if (users.names === '' || users.email === '' || users.password === '' || users.usertype === '') {
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    Object.keys(users).forEach((key) => {
      formData.append(key, users[key]);
    });

    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${users.id}`, formData);
        setAlert('User updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, formData);
        setAlert('User added successfully!');
      }
      fetchAllUsers();
      handleEventDialogClose();
    } catch (error) {
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (row) => {
    setUsers({
      id: row.id,
      names: row.names,
      email: row.email,
      password: row.password,
      usertype: row.usertype,
      profile: row.profile,
    });
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${id}`);
      setAlert('User deleted successfully!');
      fetchAllUsers();
    } catch (error) {
      setAlert('Error deleting user.');
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Box>
      {alert && (
        <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {isEditing ? 'Edit User' : 'Users Registration Form'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="names"
              label="Names"
              name="names"
              autoComplete="names"
              autoFocus
              value={users.names}
              onChange={handleUsersChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={users.email}
              onChange={handleUsersChange}
            />
            <TextField
              margin="normal"
              fullWidth
              type="password"
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              value={users.password}
              onChange={handleUsersChange}
            />

          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select User Type *</InputLabel>
            <Select
              labelId="usertype-label"
              id="usertype"
              name="usertype"
              label="Select User Type"
              value={users.usertype}
              onChange={handleUsersChange}
              fullWidth
              margin="normal"
            >
              {usertypes.map((usertype) => (
                <MenuItem key={usertype} value={usertype}>
                  {usertype}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <TextField
              margin="normal"
              fullWidth
              type="file"
              id="profile"
              name="profile"
              onChange={handleFileChange}
            />
          </FormFieldContainer>
          {alert && (
            <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
          )}
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {isEditing ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default Users;
