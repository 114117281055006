import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/Footer';
import HeroBrand from './HeroBrand';
import WhoWeare from './WhoWeare';
import ProvidedServices from './ProvidedServices';
import StudyAbroadSteps from './StudyAbroadSteps';
import HowtoapplySection from './HowtoapplySection';
import Destinations from '../pages/Destinations';

export default function LandingBrand() {
  const { id, brandid } = useParams(); // Extract id and brandid from the URL
  return (
    <div>
      <Header />
      <HeroBrand serviceId={id} brandid={brandid} /> {/* Pass id and brandid to the next component */}
      <WhoWeare serviceId={id} brandid={brandid} />
      <ProvidedServices serviceId={id} brandid={brandid} />
      <StudyAbroadSteps/>
      <HowtoapplySection/>
      <Destinations/>
      <Footer />
    </div>
  );
}
