import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, Toolbar, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TopAppBar from '../components/AppBar';
import SideDrawer from '../components/Drawer';
import DashboardContent from '../components/DashboardContent';
import WebAdministration from './WebAdministration';
import Home from './LandingPage';


const drawerWidth = 240;

const RootDashboard = () => {
  const loc = useLocation();
  const currentPage = loc.pathname;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(<Home />);


  useEffect(() => {
    switch (currentPage) {
      case "/dashboard":
        setSelectedComponent(<DashboardContent />);
        break;
      case "/webcontents":
        setSelectedComponent(<WebAdministration />);
        break;
      default:
        setSelectedComponent(<Home />);
        break;
    }
  }, [currentPage]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopAppBar handleDrawerToggle={handleDrawerToggle} />
      <SideDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <SideDrawer handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <SideDrawer />
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>

        
        <Toolbar />
        {selectedComponent}
      </Box>
    </Box>
  );
};

export default RootDashboard;

