import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Grid, Paper, IconButton, Skeleton, Grow, Fade } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './styles/team.css'; // Import the CSS file
import axios from 'axios';

export default function Team() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllTestmonials = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/team/official`);
      setTeamMembers(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllTestmonials();
  }, []);

  return (
    <Box
      sx={{
        padding: '4rem 2rem',
        backgroundColor: '#f5f5f5',
        textAlign: 'center'
      }}
    >
      <Typography variant="h3" component="h2" gutterBottom>
        Meet Our Team
      </Typography>
      <Typography variant="body1" component="p" gutterBottom sx={{ marginBottom: '2rem' }}>
        Our team is composed of experienced professionals dedicated to delivering the best results.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {loading ? (
          Array.from(new Array(8)).map((_, index) => (
            <Fade in={true} timeout={1000} key={index}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper className="team-card" elevation={3}>
                  <Skeleton variant="circular" width={150} height={150} sx={{ margin: '0 auto 1rem auto' }} />
                  <Skeleton variant="text" width="60%" sx={{ margin: '0 auto 1rem auto' }} />
                  <Skeleton variant="text" width="40%" sx={{ margin: '0 auto 1rem auto' }} />
                  <Box sx={{ marginTop: '1rem' }}>
                    <Skeleton variant="rectangular" width={40} height={40} sx={{ display: 'inline-block', margin: '0 0.5rem' }} />
                    <Skeleton variant="rectangular" width={40} height={40} sx={{ display: 'inline-block', margin: '0 0.5rem' }} />
                    <Skeleton variant="rectangular" width={40} height={40} sx={{ display: 'inline-block', margin: '0 0.5rem' }} />
                    <Skeleton variant="rectangular" width={40} height={40} sx={{ display: 'inline-block', margin: '0 0.5rem' }} />
                    <Skeleton variant="rectangular" width={40} height={40} sx={{ display: 'inline-block', margin: '0 0.5rem' }} />
                  </Box>
                </Paper>
              </Grid>
            </Fade>
          ))
        ) : (
          teamMembers.map((member, index) => (
            <Grow key={index} in={true} timeout={(index + 1) * 500}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper className="team-card" elevation={3}>
                  <Avatar
                    alt={member.names}
                    src={`${process.env.REACT_APP_API_URL}/uploads/${member.picture}`}
                    className="team-avatar"
                    sx={{ width: 200, height: 200 }}
                  />
                  <Typography variant="h6" component="p" fontWeight="bold">
                    {member.names}
                  </Typography>
                  <Typography variant="body1" component="p" color="text.secondary">
                    {member.position}
                  </Typography>
                  <Box sx={{ marginTop: '1rem' }}>
                    <IconButton component="a" href={member.linkDin} target="_blank" sx={{ color: '#0e76a8' }}>
                      <LinkedInIcon />
                    </IconButton>
                    <IconButton component="a" href={member.twitter} target="_blank" sx={{ color: '#00acee' }}>
                      <TwitterIcon />
                    </IconButton>
                    <IconButton component="a" href={member.facebook} target="_blank" sx={{ color: '#3b5998' }}>
                      <FacebookIcon />
                    </IconButton>
                    <IconButton component="a" href={member.instagram} target="_blank" sx={{ color: '#3f729b' }}>
                      <InstagramIcon />
                    </IconButton>
                    <IconButton component="a" href={`https://wa.me/${member.phone}?text=Hello%20thank%20you%20for%20contacting`} target="_blank" sx={{ color: '#25D366' }}>
                      <WhatsAppIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
            </Grow>
          ))
        )}
      </Grid>
    </Box>
  );
}
