import React, { useState } from 'react';
import { Box, Typography, Grid, IconButton, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const faqData = [
  {
    question: 'How does EarthLink Air Ticket Agency offer cheap international air tickets?',
    answer: 'EarthLink Air Ticket Agency lists out cheap international flights with the help of a dedicated team, who closely collaborate with different international airlines to shortlist flights based on specific routes and cheap fares, to be able to offer the best prices for international flight tickets to our customers.',
  },
  {
    question: 'How can I find the best deals on last-minute flights with EarthLink?',
    answer: 'At EarthLink, we understand that travel plans can be spontaneous. Our team monitors last-minute deals and cancellations with airlines, which often leads to discounted prices. You can check our website regularly or subscribe to our notifications for real-time alerts on last-minute offers, helping you book affordable flights even at the eleventh hour.',
  },
  {
    question: 'Does EarthLink offer flexible booking options for flights?',
    answer: 'Yes, EarthLink offers flexible booking options, allowing customers to choose refundable tickets or modify flight dates with minimal fees. We also provide the option to add travel insurance, ensuring peace of mind if your plans change unexpectedly.',
  },
  {
    question: 'Can EarthLink help me book multi-city or connecting flights?',
    answer: 'Absolutely! Our platform allows customers to easily book multi-city trips or connecting flights. Our system compares routes and fares across airlines, ensuring you get the best combination of flights at the most affordable rates. Our support team can also assist in tailoring itineraries based on your travel needs.',
  },
  {
    question: 'Does EarthLink provide any special discounts for students or groups?',
    answer: 'Yes, EarthLink offers exclusive discounts for students, senior citizens, and group bookings. We partner with several airlines that provide reduced fares for students traveling abroad for studies or internships, and we also offer group rates for family trips, corporate travel, and other group events.',
  },
  {
    question: 'How can I manage or cancel my booking with EarthLink?',
    answer: 'You can manage or cancel your bookings directly on our website through the "Manage Booking" section. Simply enter your booking reference and last name to view and modify your reservation. If you need assistance with cancellations or changes, our customer service team is available to guide you through the process and inform you about any applicable cancellation fees or refund policies.',
  },
];

const FAQSectionFlight = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <Box sx={{display:'flex', justifyContent:'center',alignItems:'center',backgroundColor: '#f7f7f7',}}>
    <Box
      sx={{
        padding: { xs: '20px', sm: '40px' },
        
        borderRadius: '8px',
        width:'90%'
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '40px',
          fontSize: { xs: '24px', sm: '32px' },
          color: '#2c3e50',
        }}
      >
        Frequently Asked Questions (FAQs)
      </Typography>

      <Grid container spacing={3}>
        {faqData.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Box sx={{ marginBottom: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '15px 10px',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.3s ease',
                  '&:hover': { backgroundColor: '#f0f0f0' },
                }}
                onClick={() => handleExpand(index)}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '16px', sm: '18px' },
                    color: '#34495e',
                  }}
                >
                  {faq.question}
                </Typography>
                <IconButton>
                  {expandedIndex === index ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
              </Box>

              <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ padding: '10px 15px', backgroundColor: '#fafafa' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: '14px', sm: '16px' },
                      color: '#7f8c8d',
                      lineHeight: 1.6,
                    }}
                  >
                    {faq.answer}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Box>
  );
};

export default FAQSectionFlight;
