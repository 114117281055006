import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import FolderIcon from '@mui/icons-material/Folder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion'; // Importing framer-motion for animations
import ApplyNowButton from './ApplyButton'; // Button component

const steps = [
  {
    icon: <ComputerIcon fontSize="large" style={{ color: '#b58900' }} />,
    title: 'Registration',
    description: 'Start by researching different study abroad programs, universities, and countries.',
  },
  {
    icon: <FolderIcon fontSize="large" style={{ color: '#6aaf46' }} />,
    title: 'Documentation',
    description: 'Prepare by gathering necessary documents and apply for scholarships/visas.',
  },
  {
    icon: <CheckCircleIcon fontSize="large" style={{ color: '#d57900' }} />,
    title: 'Get Started Now',
    description: 'Assess your eligibility for the desired program and get started.',
  },
];

const HowtoapplySection = () => {
  return (
    <Box
      sx={{
        padding: '60px 20px',
        textAlign: 'center',
        background: 'linear-gradient(135deg, #fdfbfb 0%, #ebedee 100%)', // Gradient background for a unique look
      }}
    >
      {/* Title Section */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          marginBottom: '40px',
          color: '#333',
        }}
      >
        How to Apply
      </Typography>

      {/* Steps Section */}
      <Grid container spacing={6} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {/* Animate each step on hover and stagger animations */}
            <motion.div
              whileHover={{ scale: 1.1 }} // Scale on hover for interactivity
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.1 * index }} // Delayed entry for each step
            >
              <Box sx={{ position: 'relative' }}>
                {/* Circular container for the icon with hover effect */}
                <Box
                  sx={{
                    width: '120px',
                    height: '120px',
                    backgroundColor: '#f7f7f7',
                    borderRadius: '50%',
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    transition: '0.3s ease', // Smooth transition
                    '&:hover': {
                      backgroundColor: '#fff',
                      boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  {step.icon}
                  {/* Step Number Badge */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      backgroundColor: '#007bff',
                      borderRadius: '50%',
                      color: '#fff',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', // Subtle shadow for badge
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>

                {/* Step Title */}
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '20px',
                    fontWeight: 'bold',
                    color: '#444',
                  }}
                >
                  {step.title}
                </Typography>

                {/* Step Description */}
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: '10px',
                    color: '#666',
                    padding: '0 10px', // Slight padding for better readability
                  }}
                >
                  {step.description}
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Apply Now Button Section */}
      <Box sx={{ marginTop: '50px' }}>
        <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <ApplyNowButton />
        </motion.div>
      </Box>
    </Box>
  );
};

export default HowtoapplySection;
