// src/WhoWeAre.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import axios from 'axios';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedBox = styled(Box)(({ theme }) => ({
  animation: `${fadeIn} 0.6s ease forwards`,
}));

export default function WhoWeAre() {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchContents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents/whoweare`);
      setContents(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '2rem', md: '2rem' },
            backgroundColor: '#f5f5f5',
          }}
        >
          <Box
            sx={{
              flex: 1,
              paddingRight: { xs: '0', md: '1rem' },
              margin: { xs: '1rem 0', md: '1rem 2rem' },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Skeleton variant="text" width="80%" height={50} />
            <Skeleton variant="text" width="60%" height={40} style={{ marginBottom: 20 }} />
            <Skeleton variant="text" width="90%" height={200} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Skeleton variant="rectangular" width={350} height={350} sx={{ borderRadius: '10px', marginBottom: '1rem' }} />
            <Skeleton variant="rectangular" width={450} height={450} sx={{ borderRadius: '10px' }} />
          </Box>
        </Box>
      ) : (
        contents.map((content, index) => (
          <AnimatedBox
            key={index}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
              padding: { xs: '2rem', md: '2rem' },
              backgroundColor: '#f5f5f5',
              marginBottom: { xs: '1rem', md: '2rem' },
            }}
          >
            <Box
              sx={{
                flex: 1,
                paddingRight: { xs: '0', md: '1rem' },
                margin: { xs: '1rem 0', md: '1rem 2rem' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography variant="h3" component="h2" gutterBottom>
                {content.title}
              </Typography>
              <Typography variant="h6" component="h3" style={{ marginBottom: 20 }}>
                {content.summary}
              </Typography>
              <Typography variant="body1" component="h3">
                {content.description}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_URL}/uploads/${content.image2}`}
                alt="Image 1"
                className="image1"
                sx={{
                  width: { xs: '100%', md: '350px' },
                  height: 'auto',
                  position: { xs: 'static', md: 'absolute' },
                  top: { xs: 0, md: '140px' },
                  left: { xs: '10%', md: '-5%' },
                  zIndex: 2,
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_URL}/uploads/${content.image1}`}
                alt="Image 2"
                className="image2"
                sx={{
                  width: { xs: '100%', md: '450px' },
                  height: 'auto',
                  position: { xs: 'static', md: 'relative' },
                  zIndex: 1,
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  marginTop: { xs: '1rem', md: '0' },
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
            </Box>
          </AnimatedBox>
        ))
      )}
    </>
  );
}
