import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Styles for the main container without the image
const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#f0f4f8',
  padding: '1rem',
}));

// Styles for the form container with a clean, modern look
const FormContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '3rem',
  maxWidth: '500px',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '20px',
  boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('md')]: {
    padding: '2rem',
  },
}));

// Styled button with a gradient effect
const GradientButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  width: '100%',
  padding: '10px 20px',
  background: 'linear-gradient(45deg, #007BFF, #00C6FF)',
  color: 'white',
  fontSize: '1.2rem',
  borderRadius: '30px',
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    background: 'linear-gradient(45deg, #0056b3, #00a1e1)',
  },
}));

// Main component
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();

  // Function to handle form submission for login
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  // Function to handle form submission for sign up
  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signup`, { name, email, password });
      setIsLogin(true); // Redirect to login after successful signup
    } catch (err) {
      setError('Error signing up, please try again');
    }
  };

  return (
    <PageContainer>
      <FormContainer elevation={10}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#007BFF' }}>
          {isLogin ? 'Welcome Back!' : 'Create an Account'}
        </Typography>
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        <Box component="form" onSubmit={isLogin ? handleLoginSubmit : handleSignUpSubmit} noValidate autoComplete="off">
          {!isLogin && (
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ borderRadius: '8px', marginBottom: '1.5rem' }}
            />
          )}
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus={isLogin}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ borderRadius: '8px', marginBottom: '1.5rem' }}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ borderRadius: '8px', marginBottom: '1.5rem' }}
          />
          {!isLogin && (
            <TextField
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ borderRadius: '8px', marginBottom: '1.5rem' }}
            />
          )}
          {isLogin && (
            <MuiLink
              component={Link}
              to="/forgot-password"
              variant="body2"
              sx={{ alignSelf: 'flex-start', mt: 1, color: '#007BFF' }}
            >
              Forgot password?
            </MuiLink>
          )}
          <GradientButton type="submit">
            {isLogin ? 'Sign In' : 'Sign Up'}
          </GradientButton>
          <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <MuiLink
            component="button"
            variant="body2"
            onClick={() => setIsLogin(!isLogin)}
            sx={{ alignSelf: 'flex-start', mt: 1, color: '#007BFF', cursor: 'pointer' }}
          >
            {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Login'}
          </MuiLink>
          <MuiLink
            component={Link}
            to="/"
            variant="body2"
            sx={{ alignSelf: 'flex-start', mt: 1, color: '#007BFF' }}
          >
            Back Home
          </MuiLink>
          </Box>
        </Box>
      </FormContainer>
    </PageContainer>
  );
};

export default LoginPage;
