import React from 'react';
import { Box, Typography } from '@mui/material';
import visaImage from '../assets/images/summer-camp.jpg'; // Replace with the actual path

const HeroCamp = () => {
  const slides = [
    {
      image: visaImage,
      title: 'Summer Camps',
      description: 'Eathlink Summer Camps programs offer a fantastic way for campers to learn, have fun, and make friends.',
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height:'80vh'
      }}
    >
      {slides.map((slide, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            width: '100%',
            height: '80vh',
            backgroundImage: `url(${slide.image})`,
            backgroundSize: 'cover',
            borderRadius: '0px',
            overflow: 'hidden',
            cursor:'pointer'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: '1rem',
              textAlign: 'center',
              alignItems:'center',
              justifyContent:'center',
              display:'flex',
              flexDirection:'column'
            }}
          >
            <Typography variant="h5" sx={{marginBottom:2}}>{slide.title}</Typography>
            <Typography variant="body1" sx={{textAlign:'justify',fontSize:20}}>{slide.description}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HeroCamp;
