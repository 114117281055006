import React, { useState } from 'react';
import PersonalInfo from './PersonalInfo';
import SchoolInfo from './ShoolInfo';
import ContactInfo from './ContactInfo';
import Confirmation from './Confirmation';
import DocumentUpload from './DocumentsUpload';  // Import the DocumentUpload component
import Paper from '@mui/material/Card';
import { Stepper, Step, StepLabel, Box, Typography } from '@mui/material';
import Header from '../components/header';
import Footer from '../components/Footer';

function StudentsForm() {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    fullName: '',
    dateOfbirth: '',
    martalStatus:'',
    passport:'',
    identification: '',
    gender: '',
    expireDate:'',
    language:'',
    nationality: '',
    educationCountry:'',
    highestlevel:'',
    grade:'',
    graduatedSchool:'',
    graduationDate:'',
    studyfield:'',
    address:'',
    email: '',
    phone: '',
    documents: [{ file: null, category: "" }] // Initialize documents field
  });

  const steps = ['Personal Information', 'Education Information', 'Contact Information', 'Upload Documents','Confirmation'];

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (input) => (e) => {
    setUserData({ ...userData, [input]: e.target.value });
  };

  const handleDocumentChange = (documents) => {
    setUserData({ ...userData, documents });
  };

  return (
    <Box>
      <Header/>
    <Box sx={{ width: '60%', margin: '0 auto', marginTop: '',paddingBottom:4 }}>
      <Box sx={{marginBottom:'50px',alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column'}}>
      <Typography sx={{fontSize:30,fontWeight:'600',textAlign:'center'}}>Student Application</Typography>
     <div style={{height:2,width:100,backgroundColor:'green'}}></div>
     </Box> 
     <Stepper activeStep={step - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper elevation={3} style={{ padding: 40, marginTop: 20 }}>
        {(() => {
          switch (step) {
            case 1:
              return (
                <PersonalInfo
                  nextStep={nextStep}
                  handleChange={handleChange}
                  userData={userData}
                />
              );
            case 2:
              return (
                <SchoolInfo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  userData={userData}
                />
              );
            case 3:
              return (
                <ContactInfo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  userData={userData}
                />
              );
            case 4:
              return (
                <DocumentUpload
                  nextStep={nextStep}
                  prevStep={prevStep}
                  documents={userData.documents}
                  setDocuments={handleDocumentChange} // Pass setDocuments to update the documents field
                />
              );
            case 5:
              return (
                <Confirmation
                  nextStep={nextStep}
                  prevStep={prevStep}
                  userData={userData}
                />
              );
            default:
              return <div>Step not found</div>;
          }
        })()}
      </Paper>
    </Box>
    <Footer/>
    </Box>
  );
}

export default StudentsForm;
