import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Skeleton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import axios from 'axios';
import './styles/hello.css';
import ApplyNowButton from '../brands/ApplyButton';

export default function Hello(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const fetchSlides = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents/welcomepage`);
      setSlides(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSlides();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <Box sx={{ backgroundSize: 'cover', position: 'relative', height: '100vh', width: '100%' }}>
      {loading ? (
        <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
          <Skeleton variant="rectangular" sx={{ height: '100%', width: '100%' }} />
          <Box
            sx={{
              width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#fff',
              textAlign: 'center',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <Skeleton variant="text" width="80%" sx={{ marginBottom: 2 }} />
            <Skeleton variant="text" width="60%" sx={{ marginTop: 2 }} />
          </Box>
        </Box>
      ) : (
        slides.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {slides.map((slide, index) => (
              <Box
                key={index}
                sx={{
                  display: index === currentIndex ? 'block' : 'none',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                }}
              >
                <video
                  src={`${process.env.REACT_APP_API_URL}/uploads/${slide.video1}`}
                 
                  autoPlay
                  loop
                  muted
                  onError={(e) => console.error('Video loading error:', e)}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                >
                  <p>Your browser does not support the video tag. You can <a href={`${process.env.REACT_APP_API_URL}/uploads/${slide.video}`}>download the video here</a>.</p>
                </video>
                <Box
                  sx={{
                    width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
                    position: 'absolute',
                    top: '50%',
                    left: '30%',
                    transform: 'translate(-50%, -50%)',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '20px',
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant="h4" sx={{ marginBottom: 2 }}>
                    {slide.title}
                  </Typography>
                  <Typography variant="h6" sx={{ marginTop: 2, textAlign: 'justify',marginBottom:5 }}>
                    {slide.summary}
                  </Typography>
                  <ApplyNowButton/>
                </Box>
              </Box>
            ))}

            <IconButton
              onClick={handlePrevious}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                color: '#fff',
                zIndex: 10,
              }}
            >
              <ArrowBackIos />
            </IconButton>

            <IconButton
              onClick={handleNext}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#fff',
                zIndex: 10,
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
        )
      )}
      {!loading && slides.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: 1,
          }}
        >
          {slides.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: index === currentIndex ? '#1976d2' : '#fff',
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
