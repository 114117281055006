import React,{useState} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import countries from '../json/countriesList.json'

const SchoolInfo = ({ nextStep, prevStep, handleChange, userData }) => {
  const {educationCountry, highestlevel,grade,graduatedSchool,graduationDate,studyfield } = userData;
  const [valid,setValid] = useState("")
  // const [selectedDate, setSelectedDate] = useState("");
  const continueStep = e => {
    e.preventDefault();
    if(educationCountry && highestlevel && grade && graduatedSchool && graduationDate){
      nextStep();
    }
    setValid("fill all informations to continue!!")
  };
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const previousStep = e => {
    e.preventDefault();
    prevStep();
  };

  return (

<Box
      component="form"
      sx={{
        display:'flex',
       flex:1,
       flexDirection:'column',
       gap:3
      }}
      noValidate
      autoComplete="off"
    >
        <h2 style={{marginBottom:20}}>Education Summary</h2>
        <h4 style={{color:'red'}}>{valid}</h4>

        <FormControl>
      <TextField
        label="Country of Education"
        variant="outlined"
        select
        fullWidth
        value={educationCountry}
        onChange={handleChange('educationCountry')}
      >
        {countries.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </TextField>
      </FormControl>
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Highest Level of Education</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={highestlevel}
          label="Highest Level of Education"
          onChange={handleChange('highestlevel')}
        >
          <MenuItem value='Primary'>Primary</MenuItem>
          <MenuItem value='Secondary'>Secondary</MenuItem>
          <MenuItem value='Undergraduate'>Undergraduate</MenuItem>
          <MenuItem value='Masters Degree'>Masters Degree</MenuItem>
          <MenuItem value='Doctoral Degree'>Doctoral Degree</MenuItem>

        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Grade Average</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={grade}
          label="Grade Average"
          onChange={handleChange('grade')}
        >
          <MenuItem value='A'>A</MenuItem>
          <MenuItem value='A-'>A-</MenuItem>
          <MenuItem value='B+'>B+</MenuItem>
          <MenuItem value='B'>B</MenuItem>
          <MenuItem value='B-'>B-</MenuItem>
          <MenuItem value='C+'>C+</MenuItem>
          <MenuItem value='C'>C</MenuItem>
          <MenuItem value='C-'>C-</MenuItem>
          <MenuItem value='D'>D</MenuItem>
          <MenuItem value='F'>F</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <TextField id="outlined-basic" label="Graduated School Name" variant="outlined" type="text" value={graduatedSchool} onChange={handleChange('graduatedSchool')} required/>
        </FormControl>

        <FormControl fullWidth>
        <TextField id="outlined-basic" label="Field of Studies" variant="outlined" type="text" value={studyfield} onChange={handleChange('studyfield')} required/>
        </FormControl>

      <FormControl fullWidth>
         <TextField defaultValue={Date()}       
         inputProps={{
         max: getCurrentDate(), 
      }} id="outlined-basic" label="Graduation Date" variant="outlined" type='date' InputLabelProps={{ shrink: true }} value={graduationDate} onChange={handleChange('graduationDate')} required />
      </FormControl>
      
     <FormControl style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      <Button variant="contained" onClick={previousStep}>Back</Button>
      <Button variant="contained" onClick={continueStep}>Next</Button>
    </FormControl>
    </Box>


  );
};

export default SchoolInfo;