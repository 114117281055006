import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/style.css'; // Import the CSS file

const ApplyNowButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/services/application-form'); // Change this to the path you want to navigate to
  };

  return (
    <button className="apply-now-button" onClick={handleClick}>
      Apply Now
    </button>
  );
};

export default ApplyNowButton;