import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ContentsWeb = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [contents, setContents] = useState({
    title: '',
    summary:'',
    description:'',
    image1: '',
    image2: '',
    image3: '',
    video1: '',
    video2: '',
    menu :'',
    brand:'',
    service:'',
    section: '',
  });
  const [alert, setAlert] = useState(null);
  const [menus, setMenus] = useState([]);
  const [brands, setBrands] = useState([]);
  const [studyServices, setstudyServices] = useState([]);
  const [sections, setSections] = useState([]);
  const [fileNames, setFileNames] = useState({
    image1: '',
    image2: '',
    image3: '',
    video1: '',
    video2: '',
  });

  const columns = [
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'summary', headerName: 'Summary', width: 260 },
    { field: 'image1', headerName: 'First Image', width: 150 },
    { field: 'image2', headerName: 'Second Image', width: 150 },
    { field: 'image3', headerName: 'Third Image', width: 150 },
    { field: 'video1', headerName: 'First video', width: 150 },
    { field: 'video2', headerName: 'Second Video', width: 150 },
    { field: 'brandId', headerName: 'Brand', width: 100 },
    { field: 'serviceId', headerName: 'Study Service', width: 100 },
    { field: 'sectionId', headerName: 'Location Section', width: 100 },
    { field: 'status', headerName: 'Current Status', width: 100 },
    { field: 'timess', headerName: 'Registration Time', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)} style={{color:'#1D74E7'}}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} style={{color:'#F3351F'}}>
            <DeleteIcon />
          </IconButton>
        </>
      )
    }
  ];

  const handleContents = (e) => {
    const { name, value } = e.target;
    setContents({
      ...contents,
      [name]: value,
    });
  };

  const handleQuillChange = (value) => {
    setContents({
      ...contents,
      description: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setContents({
      ...contents,
      [name]: files[0],
    });
    setFileNames({
      ...fileNames,
      [name]: files[0] ? files[0].name : '',
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
    setEditing(false);
    setContents({
      title: '',
      summary:'',
      description:'',
      image1: '',
      image2: '',
      image3: '',
      video1: '',
      video2: '',
      menu :'',
      brand:'',
      service:'',
      section: '',
    });
    setFileNames({
      image1: '',
      image2: '',
      image3: '',
      video1: '',
      video2: '',
    });
  };

  const handleEventDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchAllContents = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents`);
        setRows(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllContents();
  }, []);

  useEffect(() => {
    const fetchAllMenu = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/menu`);
        setMenus(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMenu();
  }, []);

  useEffect(() => {
    const fetchAllBrands = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
        setBrands(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllBrands();
  }, []);

  useEffect(() => {
    const fetchAllServices = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/study-services`);
        setstudyServices(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllServices();
  }, []);

  useEffect(() => {
    const fetchAllSection = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section`);
        setSections(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllSection();
  }, []);
  const validateForm = () => {
    if (contents.title === '' || contents.summary === '' || contents.menu=== '' || contents.section === '') {
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    Object.keys(contents).forEach(key => {
      formData.append(key, contents[key]);
    });

    try {
      if (editing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/contents/${editId}`, formData);
        setAlert('Content updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/contents`, formData);
        setAlert('Form submitted successfully!');
      }
      handleEventDialogClose();
      setTimeout(() => {
        setAlert(null)
      }, 4000);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents`);
      setRows(res.data);

    } catch (error) {
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
      setTimeout(() => {
        setAlert(null)
      }, 4000);
    }
  };

  const handleEdit = (row) => {
    setContents({
        title: row.title,
        summary: row.summary,
        description: row.description,
        image1: row.image1, // Existing filename as string
        image2: row.image2,
        image3: row.image3,
        video1: row.video1,
        video2: row.video2,
        menu: row.menuId,
        brand: row.brand,
        service: row.service,
        section: row.sectionId,
    });
    setFileNames({
        image1: row.image1,
        image2: row.image2,
        image3: row.image3,
        video1: row.video1,
        video2: row.video2,
    });
    setEditId(row.id);
    setEditing(true);
    setOpen(true);
};


  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/contents/${id}`);
      setAlert('Content deleted successfully!');

      setTimeout(() => {
        setAlert(null)
      }, 4000);

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents`);
      setRows(res.data);
    } catch (error) {
      setAlert('Error deleting content.');
      console.error('Error deleting content:', error);
      setTimeout(() => {
        setAlert(null)
      }, 4000);
    }
  };

  return (
    <FormContainer>
      <Typography variant="h4" component="h2" gutterBottom>
        Website Content Management
      </Typography>

      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add Content
      </Button>

      {alert && (
        <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>

      )}

      <Box style={{ height: 400, width: '100%', marginTop: '2rem' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.id}
        />
      </Box>

      <Dialog open={open} onClose={handleEventDialogClose} maxWidth="md" fullWidth>
        <FormContainer>
          <Typography variant="h6" component="h3" gutterBottom>
            {editing ? 'Edit Content' : 'Add Content'}
          </Typography>

          <FormFieldContainer>
            <TextField
              name="title"
              label="Title"
              value={contents.title}
              onChange={handleContents}
              required
            />

            <TextField
              name="summary"
              label="Summary"
              value={contents.summary}
              onChange={handleContents}
              required
            />
            <ReactQuill
              value={contents.description}
              onChange={handleQuillChange}
              placeholder="Enter description"
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="menu-label">Select Menu</InputLabel>
              <Select
                labelId="menu-label"
                label="Select Menu"
                id="menu"
                name="menu"
                value={contents.menu}
                onChange={handleContents}
              >
                {menus.map((menu) => (
                  <MenuItem key={menu.id} value={menu.id}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="brand-label">Select Brand</InputLabel>
              <Select
                labelId="brand-label"
                label="Select Brand"
                id="brand"
                name="brand"
                value={contents.brand}
                onChange={handleContents}
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.id} value={brand.id}>
                    {brand.brand_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="service-label">Select Study Service</InputLabel>
              <Select
                labelId="service-label"
                label="Select Study Service"
                id="service"
                name="service"
                value={contents.service}
                onChange={handleContents}
              >
                {studyServices.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {service.service_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="section-label">Select Section</InputLabel>
              <Select
                labelId="section-label"
                label="Select Section"
                id="section"
                name="section"
                value={contents.section}
                onChange={handleContents}
              >
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="file"
              name="image1"
              label="First Image"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ accept: 'image/*' }}
            />
            {fileNames.image1 && (
              <Typography variant="caption">{fileNames.image1}</Typography>
            )}

            <TextField
              type="file"
              name="image2"
              label="Second Image"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ accept: 'image/*' }}
            />
            {fileNames.image2 && (
              <Typography variant="caption">{fileNames.image2}</Typography>
            )}

            <TextField
              type="file"
              name="image3"
              label="Third Image"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ accept: 'image/*' }}
            />
            {fileNames.image3 && (
              <Typography variant="caption">{fileNames.image3}</Typography>
            )}

            <TextField
              type="file"
              name="video1"
              label="First Video"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ accept: 'video/*' }}
            />
            {fileNames.video1 && (
              <Typography variant="caption">{fileNames.video1}</Typography>
            )}

            <TextField
              type="file"
              name="video2"
              label="Second Video"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ accept: 'video/*' }}
            />
            {fileNames.video2 && (
              <Typography variant="caption">{fileNames.video2}</Typography>
            )}
          </FormFieldContainer>

          <DialogActions>
            <Button onClick={handleEventDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              {editing ? 'Update' : 'Submit'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </FormContainer>
  );
};

export default ContentsWeb;
