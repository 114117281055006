import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import axios from 'axios';
import { motion } from 'framer-motion';

export default function WhoWeare(props) {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchContents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents/whoweare-service-details`);
      setContents(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '2rem 1rem', md: '3rem 2rem' },
            backgroundColor: '#fff',
            marginTop:'80px'
          }}
        >
          <Box
            sx={{
              flex: 1,
              paddingRight: { xs: '0', md: '2rem' },
              margin: { xs: '1rem 0', md: '0 3rem' },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Skeleton variant="text" width="80%" height={50} />
            <Skeleton variant="text" width="60%" height={40} style={{ marginBottom: 20 }} />
            <Skeleton variant="text" width="90%" height={200} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Skeleton
              variant="rectangular"
              width={500}
              height={400}
              sx={{ boxShadow: '0 4px 8px rgba(0,0,0,0.15)', borderRadius: '10px' }}
            />
          </Box>
        </Box>
      ) : (
        contents.map((content, index) => (
          <motion.div
            key={index}
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
              padding: { xs: '2rem 1rem', md: '4rem 2rem' },
              backgroundColor: '#fafafa',
              width: '80%',
              marginBottom: '2rem',
              borderRadius: '10px',
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
              marginTop:'80px'
            }}
          >
            {/* <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                Who We Are
              </Typography> */}

              <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xs: '2rem', md: '5rem' },
                position: 'relative',

              }}
            >
              <motion.img
                src={`${process.env.REACT_APP_API_URL}/uploads/${content.image2}`}
                alt="Who We Are Image"
                sx={{
                  width: { xs: '100%', md: '500px' },
                  height: 'auto',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                  borderRadius: '10px',
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease',
                }}
                whileHover={{ scale: 1.05 }} // Scale image on hover
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                paddingRight: { xs: '0', md: '2rem' },
                margin: { xs: '1rem 0', md: '0 3rem' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography variant="h6" component="h3" sx={{ marginBottom: '1.5rem', color: '#666' }}>
                {content.summary}
              </Typography>
              <Typography variant="body1" component="h3" sx={{ lineHeight: 1.8, color: '#555' }}>
                {content.description}
              </Typography>
            </Box>
          </motion.div>
        ))
      )}
    </>
  );
}
