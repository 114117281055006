import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Menus = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState({
    id: '',
    title: '',
    user: '',
  });
  const [err,setErr] =useState(false);
  const [alert, setAlert] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleMenu = (e) => {
    const { name, value } = e.target;
    setMenus({
      ...menus,
      [name]: value,
    });
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 300 },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'timess', headerName: 'Registration Time', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      
      renderCell: (params) => (
        <div style={{marginLeft:50,}}>
          <IconButton onClick={() => {
            handleEdit(params.row)
            }} style={{color:'#1D74E7'}}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => {
            handleDelete(params.row.id)
            }} style={{color:'#F3351F'}}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setMenus({
      title: '',
    });
  };

  useEffect(() => {
    const fetchAllMenu = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/menu`);
        setRows(res.data);
        console.log(rows);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    if (menus.title === '') {
      setErr(true)
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  setTimeout(() => {
    setAlert(null);
    setErr(false);
  }, 7000);

  const handleSubmit = async () => {
    if (!validateForm()) {
      setErr(true)
      setAlert('Invalid form Please try again!!')
      return;      
    }

    try {
      if (isEditMode) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/menu/${menus.id}`, menus);
        setAlert('Data updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/menu`, menus);
        setAlert('Data Inserted successfully!');
      }
      handleEventDialogClose();
      setTimeout(() => {
        setAlert(null);
      }, 7000);
      // Refresh the data
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/menu`);
      setRows(res.data);
    } catch (error) {
      setErr(true)
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    }
  };

  const handleEdit = (row) => {
    setMenus(row);
    setIsEditMode(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/menu/${id}`);
      setAlert('Record deleted successfully!');
      // Refresh the data
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/menu`);
      setRows(res.data);
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    } catch (error) {
      setErr(true);
      setAlert('Error deleting record.');
      console.error('Error deleting record:', error);
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    }
  };

  return (
    <Box>
      {alert && (
        <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {isEditMode ? 'Edit Menu' : 'Registration of the menu'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Name"
              name="title"
              autoComplete="name"
              autoFocus
              onChange={handleMenu}
              value={menus.title}
            />
          </FormFieldContainer>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}>
              {isEditMode ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default Menus;
