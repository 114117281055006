import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert, IconButton, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const StudyServices = () => {
  const columns = [
    { field: 'service_title', headerName: 'Title', width: 150 },
    { field: 'brandId', headerName: 'Brand', width: 150 },
    { field: 'description', headerName: 'Description', width: 300 },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <img
          src={`${process.env.REACT_APP_API_URL}/uploads/${params.value}`}
          alt={params.row.service_title}
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [studyServices, setStudyServices] = useState({
    service_title: '',
    brand: '',
    description: '',
    image: null,
  });
  const [alert, setAlert] = useState(null);
  const [editingId, setEditingId] = useState(null);

  const handleServices = (e) => {
    const { name, value } = e.target;
    setStudyServices({
      ...studyServices,
      [name]: value,
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setEditingId(null);
    setStudyServices({
      service_title: '',
      brand: '',
      description: '',
      image: null,
    });
  };

  useEffect(() => {
    const fetchAllServices = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/study-services`);
        setRows(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllServices();
  }, []);

  useEffect(() => {
    const fetchAllBrands = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brands`);
        setBrands(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllBrands();
  }, []);

  const validateForm = () => {
    if (studyServices.service_title === '' || studyServices.brand === '' || studyServices.description === '') {
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  setTimeout(() => {
    setAlert(null);
  }, 5000);

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append('service_title', studyServices.service_title);
    formData.append('brand', studyServices.brand);
    formData.append('description', studyServices.description);
    if (studyServices.image) {
      formData.append('image', studyServices.image);
    }

    try {
      if (editingId) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/study-services/${editingId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setAlert('Form updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/study-services`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setAlert('Form submitted successfully!');
      }
      handleEventDialogClose();
      setTimeout(() => {
        setAlert(null);
      }, 4000);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/study-services`);
      setRows(res.data);
    } catch (error) {
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
      setTimeout(() => {
        setAlert(null);
      }, 4000);
    }
  };

  const handleEdit = (row) => {
    setEditingId(row.id);
    setStudyServices({
      service_title: row.service_title,
      brand: row.brand,
      description: row.description,
      image: row.image,
    });
    handleEventDialogOpen();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/study-services/${id}`);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/study-services`);
      setRows(res.data);
      setAlert('Record deleted successfully!');
      setTimeout(() => {
        setAlert(null);
      }, 4000);
    } catch (error) {
      setAlert('Error deleting record.');
      console.error('Error deleting record:', error);
      setTimeout(() => {
        setAlert(null);
      }, 4000);
    }
  };

  return (
    <Box>
      {alert && (
        <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
      )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%', marginTop: '2rem' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {editingId ? 'Edit Study Service' : 'Register a New Study Service'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="service_title"
              label="Service Title"
              name="service_title"
              autoComplete="service_title"
              autoFocus
              onChange={handleServices}
              value={studyServices.service_title}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              multiline
              rows={4}
              onChange={handleServices}
              value={studyServices.description}
            />
            <FormControl>
              <InputLabel>Brand</InputLabel>
              <Select
                labelId="brand-select-label"
                id="brand-select"
                label="Brand"
                name="brand"
                onChange={handleServices}
                value={studyServices.brand}
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.id} value={brand.id}>
                    {brand.brand_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              component="label"
            >
              Upload Image
              <input
                type="file"
                name='file'
                hidden
                onChange={(e) => setStudyServices({ ...studyServices, image: e.target.files[0] })}
              />
            </Button>
          </FormFieldContainer>
          {alert && (
            <Alert severity={alert.includes('Error') ? 'error' : 'success'}>{alert}</Alert>
          )}
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {editingId ? 'Update' : 'Save'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default StudyServices;
