import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/LandingPage';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import TravelTours from './pages/TravelToursPage';
import RwandaEducation from './pages/RwandaEducarionPage';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import BrandsHome from './brands/BrandsHome';
import LandingBrand from './brands/LandingBrand';
import StudentsForm from './brands/StudentsForm';
import Schools from './pages/SchoolsController';
import FlightPage from './brands/FlightPage';
import LandingVisa from './visas/LandingVisa';
import LandingCamps from './camps/LandingCamps';
import CampsHome from './brands/CompsHome';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/webcontents" element={<Dashboard />} />
          <Route path="/travel-tours" element={<TravelTours />} />
          <Route path="/rwanda-education" element={<RwandaEducation />} />
          <Route path="/business-abroad" element={<BrandsHome />} />
          <Route path="/study-abroad" element={<LandingBrand />} />
          <Route path="/schools" element={<Schools />} />
          <Route path="/services/service-details/:id/:brandid" element={<LandingBrand />} />
          <Route path="/services/application-form" element={<StudentsForm />} />
          <Route path="/air-ticket" element={<FlightPage />} />
          <Route path="/summer-camp" element={<LandingCamps />} />
          <Route path="/visa" element={<LandingVisa />} />
          <Route path="/international-camps" element={<CampsHome />} />
          {/* Redirect to homepage if page not found */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
