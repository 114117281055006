import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

// Import your images here
import visaImage from '../assets/images/slide1.jpg'; // Replace with the actual path
import businessImage from '../assets/images/slide2.jpg'; // Replace with the actual path
import logisticsImage from '../assets/images/slide3.png'; // Replace with the actual path

const HeroSection = () => {
  const slides = [
    {
      image: visaImage,
      title: 'Visa Consultation',
      description: 'Expert advice on visa types, eligibility, and documentation requirements based on the traveler’s profile, destination, and purpose of travel (tourism, business, education, work, etc.).',
    },
    {
      image: businessImage,
      title: 'Document Preparation',
      description: 'Assistance with collecting, preparing, and reviewing all necessary documents, including application forms, passports, photos, invitation letters, and financial statements.',
    },
    {
      image: logisticsImage,
      title: 'Application Submission',
      description: 'Completing and submitting visa applications on behalf of the client to the appropriate consulate or embassy and Tracking the progress of the application and providing status updates.',
    },
  ];

  // State for controlling the slide index (if you want automatic slide transition in the future)
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatically rotate slides every 5 seconds (optional)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Change slides every 5 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '80vh',
        overflow: 'hidden',
        position: 'relative',
        transition: 'all 0.5s ease',
      }}
    >
      {slides.map((slide, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: '0',
            left: `${index === currentSlide ? '0' : '100%'}`, // Slide in effect
            width: '100%',
            height: '100%',
            backgroundImage: `url(${slide.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: index === currentSlide ? 1 : 0,
            opacity: index === currentSlide ? 1 : 0, // Make only current slide visible
            transition: 'opacity 1s ease-in-out',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: '1rem',
              textAlign: 'center',
              opacity: '0',
              animation: index === currentSlide ? 'fadeIn 1.5s forwards' : '', // Text animation
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              {slide.title}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify' }}>
              {slide.description}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HeroSection;
