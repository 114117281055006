import React from 'react'
import Header from '../components/header'
import Footer from '../components/Footer'
import HeroCamp from './HeroCamp'
import InternationalSection from './InternationalSection'
import CampGroups from './CampGroups'

export default function LandingCamps() {
  return (
    <div>
        <Header/>
        <HeroCamp/>
        <InternationalSection/>
        <CampGroups/>
        <Footer/>
    </div>
  )
}
