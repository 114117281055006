import React from 'react';
import { Box, Typography, Grid, Paper, Avatar } from '@mui/material';
import { motion } from 'framer-motion'; // Import framer-motion for animations
import SchoolIcon from '@mui/icons-material/School';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SupportIcon from '@mui/icons-material/Support';
import ApplyNowButton from './ApplyButton'; // Button component

// Define the steps with icons and text content
const steps = [
  {
    icon: <SchoolIcon fontSize="large" />,
    title: 'Course and University Selection',
    description: 'Identify the right course and university that align with your career goals.',
    iconColor: '#3f51b5', // Define custom icon color
  },
  {
    icon: <AssignmentTurnedInIcon fontSize="large" />,
    title: 'Application Assistance',
    description: 'Get help with filling out applications, writing statements of purpose, and more.',
    iconColor: '#f50057',
  },
  {
    icon: <AttachMoneyIcon fontSize="large" />,
    title: 'Financial Planning',
    description: 'Plan your finances, including tuition fees, living expenses, and scholarships.',
    iconColor: '#4caf50',
  },
  {
    icon: <FlightTakeoffIcon fontSize="large" />,
    title: 'Visa Guidance',
    description: 'Receive guidance on the visa application process, including documentation.',
    iconColor: '#ff9800',
  },
  {
    icon: <SupportIcon fontSize="large" />,
    title: 'Pre-Post Support',
    description: 'Get support before you leave and after you arrive in your destination country.',
    iconColor: '#e91e63',
  },
];

const StudyAbroadSteps = () => {
  return (
    <Box
      sx={{
        padding: '40px 20px',
        background: 'linear-gradient(135deg, #e0f7fa 0%, #e1bee7 100%)', // Add a gradient background
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          marginBottom: '40px',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        Steps to Prepare for Your Abroad Study
      </Typography>

      {/* Grid to display steps */}
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {/* Motion.div for animations */}
            <motion.div
              whileHover={{ scale: 1.05 }} // Slightly scale on hover
              initial={{ opacity: 0, y: 30 }} // Initial animation state
              animate={{ opacity: 1, y: 0 }} // Final animation state
              transition={{ duration: 0.6, delay: 0.1 * index }} // Staggered transition
            >
              {/* Each Step Card */}
              <Paper
                elevation={5}
                sx={{
                  padding: '30px',
                  textAlign: 'center',
                  borderRadius: '15px',
                  backgroundColor: '#fff',
                  height: '100%',
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* Step Icon */}
                <Avatar
                  sx={{
                    backgroundColor: step.iconColor,
                    width: 70,
                    height: 70,
                    margin: '0 auto 20px auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {step.icon}
                </Avatar>

                {/* Step Title */}
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: '15px',
                    fontWeight: 'bold',
                    color: '#333',
                  }}
                >
                  {step.title}
                </Typography>

                {/* Step Description */}
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {step.description}
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Apply Now Button */}
      <Box sx={{ marginTop: 6 }}>
        <motion.div whileHover={{ scale: 1.1 }}>
          <ApplyNowButton />
        </motion.div>
      </Box>
    </Box>
  );
};

export default StudyAbroadSteps;
