import React from 'react';
import Header from '../components/header';
import Footer from '../components/Footer';
import { Box, Typography, Grid, Paper } from '@mui/material';
import aboutImage from '../assets/images/about.jpeg';

export default function AboutUs() {
  return (
    <div>
      <Header />
      
      {/* About Us Section */}
      <Box 
        sx={{ 
          padding: { xs: '4rem 1rem', md: '8rem 2rem' }, 
          backgroundColor: '#e0f7fa', 
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Heading */}
        <Typography 
          variant="h3" 
          component="h2" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold', 
            color: '#00695c', 
            marginBottom: '2rem', 
            fontSize: { xs: '2rem', md: '3rem' } 
          }}
        >
          About Us
        </Typography>

        {/* Grid Container for Image and Text */}
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Box 
              sx={{
                position: 'relative', 
                overflow: 'hidden', 
                borderRadius: '12px', 
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease',
                }
              }}
            >
              <img 
                src={aboutImage} 
                alt="About Us" 
                style={{ width: '100%', height: 'auto', borderRadius: '12px' }} 
              />
            </Box>
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={5} 
              sx={{ 
                padding: { xs: '2rem', md: '3rem' }, 
                textAlign: 'left', 
                backgroundColor: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  transition: 'transform 0.3s ease',
                },
              }}
            >
              {/* Who We Are Section */}
              <Typography 
                variant="h4" 
                component="h3" 
                gutterBottom 
                sx={{ 
                  fontWeight: 'bold', 
                  color: '#00796b' 
                }}
              >
                Who We Are
              </Typography>
              <Typography variant="body1" paragraph>
                We are a company committed to delivering the best services to our clients. Our team is composed of skilled professionals dedicated to achieving excellence in every project we undertake. We believe in innovation, integrity, and customer satisfaction.
              </Typography>

              {/* Our Mission Section */}
              <Typography 
                variant="h4" 
                component="h3" 
                gutterBottom 
                sx={{ 
                  fontWeight: 'bold', 
                  color: '#00796b' 
                }}
              >
                Our Mission
              </Typography>
              <Typography variant="body1" paragraph>
                Our mission is to provide exceptional services that exceed our clients' expectations. We strive to create a positive impact through our work, fostering a culture of excellence and continuous improvement.
              </Typography>

              {/* Our Vision Section */}
              <Typography 
                variant="h4" 
                component="h3" 
                gutterBottom 
                sx={{ 
                  fontWeight: 'bold', 
                  color: '#00796b' 
                }}
              >
                Our Vision
              </Typography>
              <Typography variant="body1">
                Our vision is to be a leading company in our industry, known for our commitment to quality, innovation, and customer satisfaction. We aim to set new standards of excellence and be a trusted partner for our clients.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Footer */}
      <Footer />
    </div>
  );
}
