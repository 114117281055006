import React, { useState } from 'react';
import { Box, Grid, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const Dashboard = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f44336', color: 'white' }}>
        <Typography variant="h5">Users</Typography>
        <Typography variant="h2">150</Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#2196f3', color: 'white' }}>
        <Typography variant="h5">Team Members</Typography>
        <Typography variant="h2">5</Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#4caf50', color: 'white' }}>
        <Typography variant="h5">Sections</Typography>
        <Typography variant="h2">12</Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#ff9800', color: 'white' }}>
        <Typography variant="h5">Suggestions</Typography>
        <Typography variant="h2">45</Typography>
      </Paper>
    </Grid>
  </Grid>
);

const DashboardContent = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', start: new Date(), end: new Date() });

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleEventSubmit = () => {
    setEvents([...events, { ...newEvent, start: new Date(newEvent.start), end: new Date(newEvent.end) }]);
    handleEventDialogClose();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dashboard />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
            Add Event
          </Button>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, marginTop: 20 }}
          />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <DialogTitle>Add New Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new event, please fill in the following details.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Event Title"
            type="text"
            fullWidth
            value={newEvent.title}
            onChange={handleEventChange}
          />
          <TextField
            margin="dense"
            name="start"
            label="Start Date"
            type="datetime-local"
            fullWidth
            value={newEvent.start}
            onChange={handleEventChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="end"
            label="End Date"
            type="datetime-local"
            fullWidth
            value={newEvent.end}
            onChange={handleEventChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEventDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEventSubmit} color="primary">
            Add Event
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardContent;
