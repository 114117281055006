import React, { useEffect, useState } from 'react';
import { Box, TextField, IconButton, Button, Typography, Dialog, DialogActions, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const teamtypes = ['Official', 'Client'];

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const TeamController = () => {

  const columns = [
    { field: 'names', headerName: 'Names', width: 230 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'position', headerName: 'Position', width: 150 },
    { field: 'message', headerName: 'Message', width: 160 },
    { field: 'teamType', headerName: 'Type of Member', width: 100 },
    { field: 'linkDin', headerName: 'LinkDin', width: 160 },
    { field: 'facebook', headerName: 'Facebook', width: 160 },
    { field: 'twitter', headerName: 'Twitter', width: 160 },
    { field: 'instagram', headerName: 'Instagram', width: 160 },
    {
      field: 'picture',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        <Box component="img" src={params.value} alt={params.row.names} sx={{ width: 50, height: 50 }} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => {
              handleEdit(params.row);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleDelete(params.row.id);
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [teams, setTeams] = useState({
    id: '',
    names: '',
    email:'',
    phone:'',
    position: '',
    message: '',
    teamtype: '',
    linkdin: '',
    facebook: '',
    twitter: '',
    instagram: '',
    picture: '',
  });
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);

  const handleteam = (e) => {
    const { name, value } = e.target;
    setTeams({
      ...teams,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setTeams({
      ...teams,
      picture: e.target.files[0],
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setIsEditing(false);
    setTeams({
      id: '',
      names: '',
      email:'',
      phone:'',
      position: '',
      message: '',
      teamtype: '',
      linkdin: '',
      facebook: '',
      twitter: '',
      instagram: '',
      picture: ''
    });
  };

  const fetchAllTeams = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/team`);
      const teamsData = res.data.map((team) => ({
        ...team,
        picture: team.picture ? `${process.env.REACT_APP_API_URL}/uploads/${team.picture}` : null,
      }));
      setRows(teamsData);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    fetchAllTeams();
  }, []);

  const validateForm = () => {
    if (teams.names === ''  || teams.teamtype === '') {
      setErr(true);
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append('names', teams.names);
    formData.append('email', teams.email);
    formData.append('phone', teams.phone);
    formData.append('position', teams.position);
    formData.append('message', teams.message);
    formData.append('teamtype', teams.teamtype);
    formData.append('linkdin', teams.linkdin);
    formData.append('facebook', teams.facebook);
    formData.append('twitter', teams.twitter);
    formData.append('instagram', teams.instagram);
    formData.append('picture', teams.picture);

    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/team/${teams.id}`, formData);
        setErr(false);
        setAlert('Team member updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/team`, formData);
        setErr(false);
        setAlert('Team member added successfully!');
      }
      fetchAllTeams();
      handleEventDialogClose();
    } catch (error) {
      setErr(true);
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (row) => {
    setTeams(row);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/team/${id}`);
      setErr(false);
      setAlert('Team member deleted successfully!');
      fetchAllTeams();
    } catch (error) {
      setErr(true);
      setAlert('Error deleting team member.');
      console.error('Error deleting team member:', error);
    }
  };

  return (
    <Box>
      {alert && <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {isEditing ? 'Edit Team Member' : 'Team Member Registration Form'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="names"
              label="Names *"
              name="names"
              autoComplete="names"
              autoFocus
              value={teams.names}
              onChange={handleteam}
            />
                        <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={teams.email}
              onChange={handleteam}
            />            
            <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            autoComplete="phone"
            autoFocus
            value={teams.phone}
            onChange={handleteam}
          />
            <TextField
              margin="normal"
              fullWidth
              id="position"
              label="Position"
              name="position"
              autoComplete="position"
              autoFocus
              value={teams.position}
              onChange={handleteam}
            />
            <TextField
              margin="normal"
              fullWidth
              id="message"
              label="Message"
              name="message"
              autoComplete="message"
              autoFocus
              value={teams.message}
              onChange={handleteam}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Team Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={teams.teamtype}
                label="Team Type"
                required
                name="teamtype"
                onChange={handleteam}
              >
                {teamtypes.map((teamtype) => (
                  <MenuItem key={teamtype} value={teamtype}>
                    {teamtype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              fullWidth
              id="linkdin"
              label="LinkDin"
              name="linkdin"
              autoComplete="linkdin"
              value={teams.linkdin}
              onChange={handleteam}
            />
            <TextField
              margin="normal"
              fullWidth
              id="facebook"
              label="Facebook"
              name="facebook"
              autoComplete="facebook"
              value={teams.facebook}
              onChange={handleteam}
            />
            <TextField
              margin="normal"
              fullWidth
              id="twitter"
              label="Twitter"
              name="twitter"
              autoComplete="twitter"
              value={teams.twitter}
              onChange={handleteam}
            />
            <TextField
              margin="normal"
              fullWidth
              id="instagram"
              label="Instagram"
              name="instagram"
              autoComplete="instagram"
              value={teams.instagram}
              onChange={handleteam}
            />
            <TextField
              margin="normal"
              fullWidth
              type="file"
              id="picture"
              name="picture"
              autoComplete="picture"
              onChange={handleFileChange}
            />
          </FormFieldContainer>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {isEditing ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default TeamController;
