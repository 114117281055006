import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert,IconButton } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';



const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Menus = () => {

  const columns = [
    { field: 'names', headerName: 'Names', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'message', headerName: 'Message', width: 260 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'timess', headerName: 'Registration Time', width: 230 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      
      renderCell: (params) => (
        <div style={{marginLeft:50,}}>
          <IconButton onClick={() => {
            handleEdit(params.row)
            }}  style={{color:'#1D74E7'}}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => {
            handleDelete(params.row.id)
            }} style={{color:'#F3351F'}}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState({
    names: '',
    email: '',
    phone:'',
    message:''
  });
  const [alert, setAlert] = useState(null);
  const [err,setErr] =useState(false);
  const [isEditMode, setIsEditMode] = useState(false);


  const handlesuggestion = (e) => {
    const { name, value } = e.target;
    setSuggestions({
      ...suggestions,
      [name]: value,
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setSuggestions({
      names: '',
      email: '',
      phone:'',
      message:''
    })
  };

  const handleEdit = (row) => {
    setSuggestions(row);
    setIsEditMode(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/suggestions/${id}`);
      setAlert('Record deleted successfully!');
      // Refresh the data
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions`);
      setRows(res.data);
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    } catch (error) {
      setErr(true);
      setAlert('Error deleting record.');
      console.error('Error deleting record:', error);
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    }
  };

  useEffect(() => {
    const fetchAllMenu = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions`);
        setRows(res.data);
        console.log(rows);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    if (suggestions.names === '' || suggestions.email === '' || suggestions.phone === '' || suggestions.message === '') {
      setAlert('Please fill in all required fields.');
      return false;
      
    }
    setAlert(null);
    return true;
  };

  setTimeout(() => {
    setAlert(null)
  }, 5000);

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (isEditMode) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/suggestions/${suggestions.id}`, suggestions);
        setAlert('Data updated successfully!');

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions`);
        setRows(res.data);
      } else {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions`, suggestions);
      setAlert('Form submitted successfully!');

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions`);
      setRows(res.data);
      }
      handleEventDialogClose();
      setTimeout(() => {
        setAlert(null)
      }, 5000);
    } catch (error) {
      setErr(true)
      setAlert('Error submitting form.');
      console.error('Error submitting form:', error);
      setTimeout(() => {
        setAlert(null)
      }, 5000);
    }
  };

  return (
    <Box>
                {alert && (
            <Alert severity={err? 'error' : 'success'}>{alert}</Alert>
          )}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            {isEditMode? 'Edit Suggestions  ' : 'Suggestions Registration'}
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="names"
              label="Names"
              name="names"
              value={suggestions.names}
              autoComplete="names"
              autoFocus
              onChange={handlesuggestion}
            />

          <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={suggestions.email}
              autoComplete="email"
              autoFocus
              onChange={handlesuggestion}
            />

            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={suggestions.phone}
              autoComplete="phone"
              autoFocus
              onChange={handlesuggestion}
            />

          <TextField
              margin="normal"
              fullWidth
              id="message"
              label="Message"
              name="message"
              value={suggestions.message}
              autoComplete="message"
              autoFocus
              onChange={handlesuggestion}
            />
          </FormFieldContainer>
          {alert && (
            <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>
          )}
          <DialogActions sx={{justifyContent:"space-between"}}>
            <Button 
            onClick={handleEventDialogClose} 
            color="error"       
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, width:'100%', height:50}}>
              Cancel
            </Button>
            <Button 
            onClick={handleSubmit} 
            color="primary"       
            variant="contained"
            sx={{ mt: 3, mb: 2,width:'100%', height:50 }}>
              Register
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default Menus;
