import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import Slider from 'react-slick';
import axios from 'axios';
import './styles/testimonials.css'; // Custom styles for animations
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: true, // Pause on hover for better UX
  cssEase: 'ease-in-out', // Smooth transition effect
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};

const removeDuplicates = (arr, key) => {
  return arr.filter((obj, index, self) => 
    index === self.findIndex((t) => (
      t[key] === obj[key]
    ))
  );
};

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllTestimonials = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/team/testmonials`);
      const uniqueTestimonials = removeDuplicates(res.data, 'id');
      setTestimonials(uniqueTestimonials);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllTestimonials();
  }, []);

  return (
    <Box
      sx={{
        padding: '4rem 2rem',
        backgroundColor: '#f9f9f9',
        textAlign: 'center'
      }}
      className="testimonial-section"
    >
      <Typography variant="h3" component="h2" gutterBottom sx={{ marginBottom: '3rem' }}>
        What Our Clients Say
      </Typography>
      <Slider {...settings}>
        {loading ? (
          [1, 2, 3, 4].map((_, index) => (
            <Box key={index} className="testimonial-slide skeleton-slide">
              <div className="avatar-skeleton" />
              <div className="name-skeleton" />
              <div className="message-skeleton" />
              <div className="message-skeleton" />
            </Box>
          ))
        ) : testimonials.length > 0 ? (
          testimonials.map((testimonial, index) => (
            <Box key={index} className="testimonial-slide">
              <Avatar
                alt={testimonial.names}
                src={`${process.env.REACT_APP_API_URL}/uploads/${testimonial.picture}`}
                sx={{
                  width: 100,
                  height: 100,
                  margin: '0 auto 1rem auto',
                }}
                className="animated-avatar"
              />
              <Typography
                variant="h5"
                component="p"
                fontWeight="bold"
                gutterBottom
                className="animated-text"
              >
                {testimonial.names}
              </Typography>
              <Typography
                variant="body1"
                component="blockquote"
                fontStyle="italic"
                sx={{ fontSize: '1.25rem', lineHeight: '1.75rem', marginBottom: '2rem' }}
                className="animated-text"
              >
                "{testimonial.message}"
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="h6" component="p" sx={{ textAlign: 'center', marginTop: '2rem' }}>
            No testimonials available.
          </Typography>
        )}
      </Slider>
    </Box>
  );
}
