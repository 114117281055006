import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion'; // Import framer-motion
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const statistics = [
  {
    icon: <PeopleIcon fontSize="large" style={{ color: '#fff' }} />,
    number: '30+',
    label: 'Countries',
  },
  {
    icon: <DescriptionIcon fontSize="large" style={{ color: '#fff' }} />,
    number: '8,000+',
    label: 'Recognized Courses',
  },
  {
    icon: <SchoolIcon fontSize="large" style={{ color: '#fff' }} />,
    number: '5,000+',
    label: 'Student Enrollment',
  },
  {
    icon: <AssignmentTurnedInIcon fontSize="large" style={{ color: '#fff' }} />,
    number: '99%',
    label: 'Visa Success Rate',
  },
];

const WhyusSection = () => {
  return (
    <Box sx={{ backgroundColor: '#1976d2', padding: '60px 0' }}>
      <Typography
        variant="h4"
        sx={{
          color: '#fff',
          textAlign: 'center',
          marginBottom: '40px',
          fontWeight: 'bold',
        }}
        component={motion.div} // Add motion to the Typography
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        EDUCATION BEYOND BORDERS
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: '#fff',
          textAlign: 'center',
          marginBottom: '60px',
          fontWeight: 'medium',
        }}
        component={motion.div} // Add motion to the Typography
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        WHY EarthLink Group
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {statistics.map((stat, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{ textAlign: 'center' }}
            component={motion.div} // Add motion to Grid items
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            whileHover={{ scale: 1.05 }} // Scale effect on hover
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#fff',
              }}
              component={motion.div} // Add motion to the inner Box
              whileHover={{ rotate: [0, 5, -5, 0], transition: { duration: 0.4 } }} // Rotation effect on hover
            >
              <Box sx={{ marginBottom: '20px' }}>{stat.icon}</Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 'bold' }}
                component={motion.div} // Animate text
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 * index }}
              >
                {stat.number}
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: '10px' }}
                component={motion.div} // Animate text
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 * index }}
              >
                {stat.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyusSection;
