import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper, Alert, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from 'axios';
import Header from '../components/header';
import Footer from '../components/Footer';


const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));
export default function ContactUs() {
  const [suggestions, setSuggestions] = useState({
    names: '',
    email: '',
    phone: '',
    message: ''
  });
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState(true);

  const validateForm = () => {
    if (suggestions.names === '' || suggestions.email === '' || suggestions.message === '') {
      setAlert('Please fill in all required fields.');
      setErr(true);
      return false;
    }
    setAlert(null);
    return true;
  };

  useEffect(() => {
    const fetchAllInfo = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/other-info`);
        setInfos(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllInfo();
  }, []);

  const handlesuggestion = (e) => {
    const { name, value } = e.target;
    setSuggestions({
      ...suggestions,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions`, suggestions);
      setAlert('Your Suggestion is submitted successfully Thank you!');
      setErr(false);
      setSuggestions({ names: '', email: '', phone: '', message: '' });
    } catch (error) {
      setErr(true);
      setAlert('Error submitting message.');
      console.error('Error submitting message:', error);
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ padding: '8rem 2rem', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h3" component="h2" gutterBottom textAlign="center">
          Contact Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <FormPaper elevation={3} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="h4" component="p" gutterBottom>
                Send Us a Message
              </Typography>
              <Box component="div" sx={{ display: 'flex', width: 100, height: 5, background: 'aquamarine', marginBottom: 3, alignItems: 'center' }} />

              <Typography variant="body1" component="p" gutterBottom>
                Please if you have any suggestion or a problem provide the following information and your suggestion or a problem.
              </Typography>

              {alert && (
                <Alert sx={{ marginBottom: 3 }} severity={err ? 'error' : 'success'}>{alert}</Alert>
              )}
              <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="names"
                  value={suggestions.names}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  name="email"
                  value={suggestions.email}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  type="phone"
                  name="phone"
                  value={suggestions.phone}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  name="message"
                  value={suggestions.message}
                  onChange={handlesuggestion}
                  rows={4}
                  sx={{ marginBottom: '1rem' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Box>
            </FormPaper>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormPaper elevation={3} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="h4" component="p" gutterBottom>
                Contact Information
              </Typography>
              <Box component="div" sx={{ display: 'flex', width: 100, height: 5, background: 'aquamarine', marginBottom: 3, alignItems: 'center' }} />
              {loading ? (
                <>
                  <Skeleton variant="text" height={30} width="80%" />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="text" height={20} width="60%" sx={{ mt: 2 }} />
                  <Skeleton variant="rectangular" height={320} width="100%" sx={{ mt: 2 }} />
                </>
              ) : (
                infos.map((info, index) => (
                  <div key={index}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <LocationOnIcon sx={{ marginRight: '0.5rem' }} />
                      <Typography variant="body1" component="p">
                        {info.location}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={2}>
                      <EmailIcon sx={{ marginRight: '0.5rem' }} />
                      <Typography variant="body1" component="p">
                        {info.email}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={2}>
                      <PhoneIcon sx={{ marginRight: '0.5rem' }} />
                      <Typography variant="body1" component="p">
                        {info.phone}
                      </Typography>
                    </Box>
                  </div>
                ))
              )}
              {!loading && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0867077304636!2d144.96328!3d-37.813628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5779b971c43c2a7!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1624152117638!5m2!1sen!2sau"
                  width="100%"
                  height="320"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Location"
                ></iframe>
              )}
            </FormPaper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
