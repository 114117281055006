import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Modal, TextField, Alert, Paper } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import './styles/style.css'; // Optional: Add custom styles here
import axios from 'axios';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import uk from '../assets/images/uk.jpg';
import canada from '../assets/images/canada.jpg';
import china from '../assets/images/china.jpg';
import usa from '../assets/images/usa.jpg';
import france from '../assets/images/france.jpg';

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const GoodToknow = () => {
  const visaData = [
    { name: 'UK', imgSrc: uk, link: '/uk-visa' },
    { name: 'Canada', imgSrc: canada, link: '/canada-visa' },
    { name: 'USA', imgSrc: usa, link: '/usa-visa' },
    { name: 'France', imgSrc: france, link: '/france-visa' },
    { name: 'China', imgSrc: china, link: '/china-visa' },
  ];

  const [suggestions, setSuggestions] = useState({
    names: '',
    email: '',
    phone: '',
    message: ''
  });
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);

  const handlesuggestion = (e) => {
    const { name, value } = e.target;
    setSuggestions({
      ...suggestions,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (suggestions.names === '' || suggestions.email === '' || suggestions.message === '') {
      setAlert('Please fill in all required fields.');
      setErr(true);
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions`, suggestions);
      setAlert('Your message is successfully sent!');
      setErr(false);
      setSuggestions({ names: '', email: '', phone: '', message: '' });
    } catch (error) {
      setErr(true);
      setAlert('Error occurred in sending message.');
      console.error('Error occurred in sending message:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true });
  const { ref: visaGridRef, inView: visaGridInView } = useInView({ triggerOnce: true });

  return (
    <div className="visa-section">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className={`good-to-know ${titleInView ? 'animate' : ''}`} ref={titleRef}>
          <h3>Good to know</h3>
          <p>
            Whether for a Visa or an eVisa, <strong>EarthLink</strong> takes care of everything for you.
            Find the country and information you are looking for on this page!
          </p>
          <p>
            For greater <strong>Efficiency</strong> and to guarantee you the best <strong>Quality of Service</strong>, we submit your Visa application files 
            (complete) and, once the Visa has been obtained, we return it to you within the shortest lead time.
          </p>
        </div>
      </div>

      <div>
        <h2 className={`visa-title ${visaGridInView ? 'animate' : ''}`} ref={visaGridRef}>The most frequently requested Visas</h2>
        <p className="visa-subtitle">
          One of the best Visa experts for more than 20 years, VTI covers a wide range of countries. 
          Find these most requested Visas as well as the complete list of countries covered by VTI.
        </p>

        <div className="visa-grid">
          {visaData.map((visa) => (
            <div key={visa.name} className={`visa-card ${visaGridInView ? 'slide-up' : ''}`}>
              <img src={visa.imgSrc} alt={visa.name} className="visa-image" />
              <div className="visa-card-info">
                <h3>{visa.name}</h3>
                <button className="visa-button" onClick={handleOpen}>Contact Us</button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal Form */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '60%', md: '50%' },
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}>
            <Grid item xs={12} md={6}>
              <FormPaper elevation={3} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h4" component="p" gutterBottom>
                  Send Us a Message
                </Typography>
                <Box component="div" sx={{ display: 'flex', width: 100, height: 5, background: 'aquamarine', marginBottom: 3, alignItems: 'center' }} />

                <Typography variant="body1" component="p" gutterBottom>
                  Contact Us for more information
                </Typography>

                {alert && (
                  <Alert sx={{ marginBottom: 3 }} severity={err ? 'error' : 'success'}>{alert}</Alert>
                )}
                <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="names"
                    value={suggestions.names}
                    onChange={handlesuggestion}
                    sx={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    type="email"
                    name="email"
                    value={suggestions.email}
                    onChange={handlesuggestion}
                    sx={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    type="phone"
                    name="phone"
                    value={suggestions.phone}
                    onChange={handlesuggestion}
                    sx={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    name="message"
                    value={suggestions.message}
                    onChange={handlesuggestion}
                    rows={4}
                    sx={{ marginBottom: '1rem' }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                </Box>
              </FormPaper>
            </Grid>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default GoodToknow;
