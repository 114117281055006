import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Import logos
import dabline from '../assets/images/dabline.webp';
import Header from '../components/header';
import Footer from '../components/Footer';
import ApplyNowButton from '../brands/ApplyButton';

// You can replace these URLs with the actual links to each university's page
const universityLinks = {
  deakin: '/services/application-form',
  ecu: '/services/application-form',
  eit: '/services/application-form',
  torrens: '/services/application-form',
  newcastle: '/services/application-form',
  tasmania: '/services/application-form',
  murdoch: '/services/application-form',
  ducere: '/services/application-form',
};

const LogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '2rem', // Space between logos
  padding: '1rem',
});

const Logo = styled('img')({
  height: '100px', // Adjust size according to your needs
  width: 'auto',
  cursor: 'pointer',
});

const Schools = () => {
  return (
    <>
      <Header />
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Australia Universities
        </Typography>

        <LogoContainer>
          <a href={universityLinks.deakin} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Deakin University" />
          </a>
          <a href={universityLinks.ecu} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Edith Cowan University" />
          </a>
          <a href={universityLinks.eit} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Engineering Institute of Technology" />
          </a>
          <a href={universityLinks.torrens} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Torrens University" />
          </a>
          <a href={universityLinks.newcastle} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="University of Newcastle" />
          </a>
          <a href={universityLinks.tasmania} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="University of Tasmania" />
          </a>
          <a href={universityLinks.murdoch} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Murdoch University" />
          </a>
          <a href={universityLinks.ducere} target="" rel="noopener noreferrer">
            <Logo src={dabline} alt="Ducere Global Business School" />
          </a>
        </LogoContainer>
        <ApplyNowButton/>
      </Box>
      <Footer />
    </>
  );
};

export default Schools;
