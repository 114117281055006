import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import './styles/services.css';
import axios from 'axios';
import Footer from '../components/Footer';
import Header from '../components/header';

export default function TravelTours() {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  const FetchContents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/travel-tours`);
      setContents(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchContents();
  }, []);

  return (
    <div>
      <Header />
      <Box sx={{ padding: '8rem 2rem', backgroundColor: '#f5f5f5', textAlign: 'center' }}>
        {loading ? (
          <Box sx={{ textAlign: 'left', maxWidth: '1000px', margin: '0 auto' }}>
            <Skeleton variant="text" height={40} width="60%" sx={{ marginBottom: '1rem' }} />
            <Skeleton variant="text" height={30} width="80%" sx={{ marginBottom: '1rem' }} />
            <Skeleton variant="rectangular" height={600} width="100%" sx={{ marginBottom: '1rem' }} />
            <Skeleton variant="text" height={20} width="100%" sx={{ marginBottom: '0.5rem' }} />
            <Skeleton variant="text" height={20} width="100%" sx={{ marginBottom: '0.5rem' }} />
            <Skeleton variant="text" height={20} width="100%" sx={{ marginBottom: '0.5rem' }} />
          </Box>
        ) : (
          contents.map((content) => (
            <Box key={content.id} sx={{ textAlign: 'left', maxWidth: '1000px', margin: '0 auto' }}>
              <Typography variant="h3" gutterBottom sx={{ marginTop: '2rem' }}>
                {content.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {content.summary}
              </Typography>
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${content.image1}`}
                alt={content.title}
                style={{ width: '100%', height: 600, borderRadius: '8px' }}
              />
              <Box
                sx={{ textAlign: 'justify', marginTop: '1rem' }}
                dangerouslySetInnerHTML={{ __html: content.description }}
              />
            </Box>
          ))
        )}
      </Box>
      <Footer />
    </div>
  );
}
