import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WebIcon from '@mui/icons-material/Web';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SectionIcon from '@mui/icons-material/ViewModule';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import SuggestionIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';

const drawerWidth = 240;

const SideDrawer = ({ mobileOpen, handleDrawerToggle }) => {
  const drawer = (
    <div>
      <Toolbar style={{ marginTop: '1rem' }} />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon><HomeIcon color='primary' /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><DashboardIcon color='primary' /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/menus">
          <ListItemIcon><MenuBookIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Menus" />
        </ListItem>
        <ListItem button component={Link} to="/webcontents" >
          <ListItemIcon><WebIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Web Administration" />
        </ListItem>
        <ListItem button component={Link} to="/servirces">
          <ListItemIcon><SubscriptionsIcon color='primary'/></ListItemIcon>
          <ListItemText primary="servirces" />
        </ListItem>
        <ListItem button component={Link} to="/section">
          <ListItemIcon><SectionIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Section" />
        </ListItem>
        <ListItem button component={Link} to="/users">
          <ListItemIcon><PeopleIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to="/team">
          <ListItemIcon><GroupIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Team" />
        </ListItem>
        <ListItem button component={Link} to="/suggestions">
          <ListItemIcon><SuggestionIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Suggestions" />
        </ListItem>
        <ListItem button component={Link} to="/other">
          <ListItemIcon><InfoIcon color='primary'/></ListItemIcon>
          <ListItemText primary="Other Information" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default SideDrawer;
