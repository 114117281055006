import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const SectionWebController = () => {


  const columns = [
    { field: 'title', headerName: 'Title', width: 330 },
    { field: 'status', headerName: 'Status', width: 160 },
    { field: 'timess', headerName: 'Registration Time', width: 330 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleEdit(params.row)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState({
    id: '',
    title: '',
    menu: '',
    submenu: '',
  });
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);
  const [menus, setMenus] = useState([]);
  const [studyServices, setstudyServices] = useState([]);

  const handlesection = (e) => {
    const { name, value } = e.target;
    setSection({
      ...section,
      [name]: value,
    });
  };

  const handleEventDialogOpen = () => {
    setOpen(true);
  };

  const handleEventDialogClose = () => {
    setOpen(false);
    setSection({
      id: '',
      title: '',
      menu: '',
      submenu: '',
    });
  };

  useEffect(() => {
    fetchAllSections();
  }, []);

  const fetchAllSections = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section`);
      setRows(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAllMenus = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/menu`);
        setMenus(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllMenus();
  }, []);

  useEffect(() => {
    const fetchAllstudyServices = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/study-services`);
        setstudyServices(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllstudyServices();
  }, []);

  const validateForm = () => {
    if (section.title === '' || section.menu === '') {
      setErr(true);
      setAlert('Please fill in all required fields.');
      return false;
    }
    setAlert(null);
    return true;
  };

  const handleEdit = (row) => {
    setSection(row);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/section/${id}`);
      setAlert('Section deleted successfully!');
      setErr(false);
      fetchAllSections();
    } catch (error) {
      setErr(true);
      setAlert('Error deleting section.');
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (section.id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/section/${section.id}`, section);
        setAlert('Section updated successfully!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/section`, section);
        setAlert('Section added successfully!');
      }
      setErr(false);
      handleEventDialogClose();
      fetchAllSections();
    } catch (error) {
      setErr(true);
      setAlert('Error submitting form.');
    }
  };

  return (
    <Box>
      {alert && <Alert severity={err ? 'error' : 'success'}>{alert}</Alert>}
      <Button variant="contained" color="primary" onClick={handleEventDialogOpen}>
        Add New
      </Button>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          sx={{ justifyContent: 'space-between' }}
        />
      </Box>
      <Dialog open={open} onClose={handleEventDialogClose}>
        <FormContainer>
          <Typography variant="h4" component="h2" gutterBottom>
            Registration of the menu
          </Typography>
          <FormFieldContainer>
            <TextField
              margin="normal"
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
              value={section.title}
              onChange={handlesection}
            />
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Menu</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={section.menu}
              label="Select Menu"
              name="menu"
              onChange={handlesection}
            >
              {menus.map((menu) => (
                <MenuItem key={menu.id} value={menu.id}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
            </FormControl>

            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Submenu</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={section.submenu}
              label="Select Submenu"
              name="submenu"
              onChange={handlesection}
            >
              {studyServices.map((submenu) => (
                <MenuItem key={submenu.id} value={submenu.id}>
                  {submenu.title}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </FormFieldContainer>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleEventDialogClose}
              color="error"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '100%', height: 50 }}
            >
              {section.id ? 'Update' : 'Register'}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Box>
  );
};

export default SectionWebController;
