import React from 'react';
import { Box, Typography, Grid, CardContent, CardMedia, Container, Button } from '@mui/material';
import './styles/style.css';
import mainImage from '../assets/images/camps_hero.jpg';
import campImage from '../assets/images/camp_international.jpg'; // Add your camp image here
import Header from '../components/header';

export default function CampsHome() {

  const handleVisitWebsiteClick = () => {
    window.open('https://camps.earthlinkgroup.com', '_blank'); // Open the website in a new tab
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Header />
      
      {/* Hero Section */}
      <Box
        sx={{
          height: '60vh',
          backgroundImage: `url(${mainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" component="div">
          Welcome to EarthLink International Camps
        </Typography>
      </Box>

      {/* Camps Overview Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
          Explore Our International Camp Programs
        </Typography>
        
        {/* New Camp International Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              height="300"
              image={campImage} // Image for helping people attend international camps
              alt="Helping youth experience international camps"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Join Our International Camp Programs
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                EarthLink International Camps offers unique experiences for youth to engage in cultural exchange, outdoor activities, and personal growth. 
                Our camps provide a safe, enriching environment to foster friendships, learn new skills, and explore the world around you.
              </Typography>
              <Button variant="contained" color="primary" onClick={handleVisitWebsiteClick}>
                Visit Website
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      </Container>

      {/* Picture with Camp Theme Description */}
      <Box
        sx={{
          backgroundImage: `url(${mainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '40vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          p: 4,
        }}
      >
        <Typography variant="h4" component="div">
          Discover a world of adventure and friendship.
        </Typography>
      </Box>

      {/* Big Footer */}
      <Box sx={{ backgroundColor: '#1976d2', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                About Us
              </Typography>
              <Typography variant="body2">
                EarthLink International Camps provides impactful and transformative camp experiences that inspire growth and global understanding.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: info@earthlinkcamps.com
                <br />
                Phone: +250 788 447 599
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Follow Us
              </Typography>
              <Typography variant="body2">
                Facebook | Twitter | Instagram | LinkedIn
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
