import React from 'react';
import './styles/style.css'; // External CSS for styling

const CampGroups = () => {
  return (
    <div className="groups-container">
      <header className="groups-header">
        <h1 className="main-title">Who Do We Cater To?</h1>
        <p className="main-subtitle">
          Our international camps offer tailored experiences for different age groups, ensuring fun, learning, and personal growth for everyone.
        </p>
      </header>

      <section className="group-section">
        <div className="group-image children"></div>
        <div className="group-content">
          <h2 className="group-title">Children</h2>
          <p className="group-description">
            Children aged 7-12 can let their imagination run wild in a safe and fun environment. We offer creative activities that inspire learning and play while helping them build friendships.
          </p>
        </div>
      </section>

      <section className="group-section reverse">
        <div className="group-image teenagers"></div>
        <div className="group-content">
          <h2 className="group-title">Teenagers</h2>
          <p className="group-description">
            Teenagers aged 13-17 engage in adventurous and mentally stimulating challenges. From outdoor adventures to leadership programs, we help teens develop confidence and independence.
          </p>
        </div>
      </section>

      <section className="group-section">
        <div className="group-image parents"></div>
        <div className="group-content">
          <h2 className="group-title">Parents</h2>
          <p className="group-description">
            Parents can participate alongside their children or enjoy their own activities, such as relaxation or learning workshops. Our programs are designed to provide meaningful family time and individual growth.
          </p>
        </div>
      </section>

      <section className="group-section reverse">
        <div className="group-image groups"></div>
        <div className="group-content">
          <h2 className="group-title">Groups</h2>
          <p className="group-description">
            Whether you are coming as a class, a sports team, or a group of friends, our camps offer a collaborative experience. We ensure that groups bond through exciting and enriching activities.
          </p>
        </div>
      </section>
    </div>
  );
};

export default CampGroups;
