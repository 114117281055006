import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia, Modal, TextField,Alert,Paper } from '@mui/material';
import { styled } from '@mui/system';
import holiday from '../assets/images/holday.jpg';
import hotel from '../assets/images/hotel.jpg';
import business from '../assets/images/business.png';
import leisure from '../assets/images/leisure.jpg';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';

const FormPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  }));

const offerData = [
  {
    category: 'HOLIDAYS',
    title: 'Planning a holiday can be exciting yet overwhelming.',
    description: 'A well-organized trip starts with setting a budget, choosing the right destination, and booking at the best time to avoid high-season costs. Whether it’s a family getaway or a solo adventure, always research local customs, pack light, and ensure travel insurance is in place.',
    img: holiday, 
    actionText: 'Contact Us',
  },
  {
    category: 'HOTELS',
    title: 'FOR UNFORGETTABLE FESTIVE TRIPS:',
    description: 'These hotels focus on providing basic amenities such as clean rooms, Wi-Fi, and continental breakfast at a reasonable price. Ideal for those who need a convenient place to stay while spending more of their time exploring the destination.',
    img: hotel,
    actionText: 'Contact Us',
  },
  {
    category: 'LEISURE',
    title: 'Leisure trips are all about relaxation, exploration, and enjoyment.',
    description: 'These trips can range from beach vacations to city tours or nature retreats. They focus on unwinding, experiencing new cultures, and indulging in activities like sightseeing, dining, and spa treatments.',
    img: leisure,
    actionText: 'Contact Us',
  },
  {
    category: 'BUSINESS',
    title: 'Business trips are centered around productivity and efficiency.',
    description: 'Whether attending meetings, conferences, or site visits, a well-structured schedule is crucial. Packing smart with essential gadgets, business attire, and travel-friendly documentation is key. To maximize efficiency, book flights and accommodation.',
    img: business,
    actionText: 'Contact Us',
  },
];

const BookFlightInfo = () => {

const [suggestions, setSuggestions] = useState({
        names: '',
        email: '',
        phone: '',
        message: ''
      });
  const [open, setOpen] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(false);
  const handlesuggestion = (e) => {
    const { name, value } = e.target;
    setSuggestions({
      ...suggestions,
      [name]: value,
    });
  };
  const validateForm = () => {
    if (suggestions.names === '' || suggestions.email === '' || suggestions.message === '') {
      setAlert('Please fill in all required fields.');
      setErr(true);
      return false;
    }
    setAlert(null);
    return true;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions`, suggestions);
      setAlert('Your Suggestion is submitted successfully Thank you!');
      setErr(false);
      setSuggestions({ names: '', email: '', phone: '', message: '' });
    } catch (error) {
      setErr(true);
      setAlert('Error submitting Suggestion.');
      console.error('Error submitting Suggestion:', error);
    }
  };

  const handleOpen = (offer) => {
    setCurrentOffer(offer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentOffer(null);
  };

  return (
    <Box sx={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, width:'90%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        Offers
      </Typography>

      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        {offerData.map((offer, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                height="220"
                image={offer.img} // Replace with your image path
                alt={offer.category}
              />
              <CardContent>
                <Typography variant="overline" sx={{ fontWeight: 'bold', color: '#9e9e9e' }}>
                  {offer.category}
                </Typography>
                <Typography variant="h6" sx={{ margin: '10px 0' }}>
                  {offer.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px', textAlign: 'justify' }}>
                  {offer.description}
                </Typography>
                <Button variant="contained" color="primary" onClick={() => handleOpen(offer)}>
                  {offer.actionText}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      </Box>
      {/* Modal Form */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '60%', md: '50%' },
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}>
          <Grid item xs={12} md={6}>
            <FormPaper elevation={3} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="h4" component="p" gutterBottom>
                Send Us a Message
              </Typography>
              <Box component="div" sx={{ display: 'flex', width: 100, height: 5, background: 'aquamarine', marginBottom: 3, alignItems: 'center' }} />

              <Typography variant="body1" component="p" gutterBottom>
              Contact Us about {currentOffer?.category}
              </Typography>

              {alert && (
                <Alert sx={{ marginBottom: 3 }} severity={err ? 'error' : 'success'}>{alert}</Alert>
              )}
              <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="names"
                  value={suggestions.names}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  name="email"
                  value={suggestions.email}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  type="phone"
                  name="phone"
                  value={suggestions.phone}
                  onChange={handlesuggestion}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  name="message"
                  value={suggestions.message}
                  onChange={handlesuggestion}
                  rows={4}
                  sx={{ marginBottom: '1rem' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Box>
            </FormPaper>
          </Grid>

        </Box>
      </Modal>
      </Box>
  );
};

export default BookFlightInfo;
