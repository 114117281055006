import React from 'react';
import './styles/style.css';

const HeroFlight = () => {
  return (
    <div className="container">
      
      {/* Banner */}
      <section className="banner">
        <div className="banner-content">
          <h1>Enjoy Your Flight With EarthLink Agency</h1>
        </div>
      </section>

      {/* Privilege Club Pop-up */}
      <div className="privilege-club">
        <h2>Join Trusted Agency</h2>
        <ul>
          <li>Our travel deals will help you in booking a cheap flight</li>
          <li>Book your Air ticket with Us</li>
          <li>We help you get Hotel and Car services</li>
        </ul>
        <button className="join-now-btn">Contact Us</button>
      </div>

      {/* Air Ticket Services Section */}
      <section className="services-section">
        <h2 className="services-title">Our Air Ticket Services</h2>
        <div className="services-grid">
          <div className="service-box">
            <h3>Cheap Flights</h3>
            <p>We offer affordable flights to your favorite destinations worldwide, ensuring you get the best deals.</p>
          </div>
          <div className="service-box">
            <h3>Last-Minute Deals</h3>
            <p>Take advantage of our last-minute flight offers, saving you money even when booking on short notice.</p>
          </div>
          <div className="service-box">
            <h3>Flexible Booking</h3>
            <p>Book with confidence by choosing refundable options or easily modifying your travel dates.</p>
          </div>
          <div className="service-box">
            <h3>Multi-City Flights</h3>
            <p>Plan a multi-city trip and explore different destinations with our easy-to-book connecting flights.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroFlight;
