import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Card, CardContent, Button, Grid, Skeleton } from '@mui/material';
import { motion } from 'framer-motion'; // Animation library

export default function ProvidedServices(props) {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});

  const fetchAllLocations = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents/provided-service-details`);
      setDestinations(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const handleReadMoreToggle = (id) => {
    setExpanded(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const maxSummaryLength = 200;

  return (
    <Box sx={{ padding: '4rem 2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
        What We Do
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {loading ? (
          [1, 2, 3, 4].map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                variant="outlined"
                sx={{
                  maxWidth: 300,
                  margin: '0 auto',
                  height: '100%',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  borderRadius: '12px',
                }}
              >
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Skeleton variant="rectangular" width={100} height={60} sx={{ margin: '0 auto' }} />
                    <Typography variant="h4" gutterBottom sx={{ marginTop: '1rem' }}>
                      <Skeleton />
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </Typography>
                  </Box>
                </CardContent>
                <Box sx={{ padding: '1rem' }}>
                  <Skeleton variant="rectangular" width={140} height={36} />
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          destinations.map(destination => (
            <Grid item xs={12} sm={6} md={3} key={destination.id}>
              <motion.div
                whileHover={{ scale: 1.05 }} // Animation on hover
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * destination.id }} // Stagger animation on load
              >
                <Card
                  variant="outlined"
                  sx={{
                    maxWidth: 500,
                    margin: '0 auto',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    borderRadius: '12px',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <motion.img
                        src={`${process.env.REACT_APP_API_URL}/uploads/${destination.image1}`}
                        alt={destination.title}
                        style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                        whileHover={{ scale: 1.1 }} // Subtle image zoom on hover
                      />
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          marginTop: '1rem',
                          textTransform: 'capitalize',
                          fontWeight: '600',
                          color: '#333',
                        }}
                      >
                        {destination.title}
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: '1rem', textAlign: 'justify', color: '#555' }}>
                        {expanded[destination.id] || destination.summary.length <= maxSummaryLength ? (
                          <>
                            {destination.summary}
                            {destination.summary.length > maxSummaryLength && (
                              <Button
                                size="small"
                                onClick={() => handleReadMoreToggle(destination.id)}
                                sx={{ padding: 0, marginLeft: 1, textTransform: 'none', color: '#007bff' }}
                              >
                                Read Less
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {`${destination.summary.substring(0, maxSummaryLength)}...`}
                            <Button
                              size="small"
                              onClick={() => handleReadMoreToggle(destination.id)}
                              sx={{ padding: 0, marginLeft: 1, textTransform: 'none', color: '#007bff' }}
                            >
                              Read More
                            </Button>
                          </>
                        )}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}
